import React, { useState, useEffect } from "react";
import { useDispatch} from 'react-redux'
import { useLocation, useNavigate, useParams } from "react-router-dom"
import { Button, Row, Col, notification, Space, Table, Tag } from 'antd';
import { CheckOutlined, CloseOutlined, ArrowLeftOutlined, UserOutlined, TeamOutlined } from '@ant-design/icons';
import {  uri_img } from "../../assets/utils/http-request";
import { calculateAge, downloadCSVFromJSON, fetchPaginatedData } from "../../assets/utils/lib";
import './org.css';
import { useAuth } from "../../assets/utils/useAuth";
import Helpers from "../../assets/utils/Helpers";

import { BiFemaleSign, BiMaleSign, BiGroup, BiUser } from "react-icons/bi";

const OrganizationDetail = props => {
  const {org} = useParams()
  const {set, user} = useAuth();
  const navigate = useNavigate();
  const dispatcher = useDispatch();
  const {state} = useLocation();

  const [orgData, setOrgData] = useState([])

  useEffect(() => {
    if(user){
      if(![1,3,4,5,6].includes(Number(user.role['staffRoleId']))) {
        Helpers.logout(set, dispatcher);
        notification.info({
          message: 'Invalid User',
          description: 'Please login with the right permission',
          placement: 'topLeft'
        })
        navigate('/login')
      }
    }else{  
      navigate('/login')
    }
  })

  async function fetchAllData(url, payload = {}) {
    for await (const data of fetchPaginatedData('post', url, payload)) {
      // console.log(data)
      setOrgData(d => ([...new Set([...d, ...data])]))
    }
  }

  useEffect(() => {
    if(!state || !org) navigator('/corporations')
    const fet = async () => await fetchAllData('organizationUsers', {hash: org})
    fet();
  }, []); //eslint-disable-line react-hooks/exhaustive-deps

  const expandedRowRender = (record, index, indent, expanded) => {
    const columns = [
      {
        title: '',
        dataIndex: 'pic_name',
        key: 'pic_name',
        render: (text) => expanded && <img src={`${uri_img}${text}`} style={{width: 50, height: 50, borderRadius: '50%'}} alt=""/>
      },
      {
        title: 'Surname',
        dataIndex: 'surname',
        key: 'surname',
      },
      {
        title: 'First Name',
        dataIndex: 'first_name',
        key: 'first_name',
      },
      {
        title: 'Insurance No',
        dataIndex: 'insurance_no',
        key: 'insurance_no',
      },
      {
        title: 'Relationship',
        dataIndex: 'relationship',
        key: 'relationship',
      },
      {
        title: 'Age',
        dataIndex: 'age',
        render: (__, record) => calculateAge(record.date_of_birth)
      },
      {
        title: 'Gender / Role',
        dataIndex: 'gender',
        key: 'gender',
        render: (text, record) => {
          return <>
            <Tag 
              color={text.toLowerCase().trim() === 'female'? "error" : "success"} 
              icon={text.toLowerCase().trim() === 'female'? <BiFemaleSign /> : <BiMaleSign />} 
              style={{display: 'flex', alignItems: 'center', maxWidth: 'fit-content'}}
            >
              {text.toUpperCase()}
            </Tag>
            <Tag 
              color={record.role.toLowerCase().trim() === 'principal'? "error" : "success"} 
              icon={record.role.toLowerCase().trim() === 'principal'? <BiUser /> : <BiGroup />} 
              style={{display: 'flex', alignItems: 'center', maxWidth: 'fit-content'}}
            >
              {record.role.toUpperCase()}
            </Tag>
          </>
        }
      }
    ];

    const data = record.enrollees

    return <Table columns={columns} dataSource={data} pagination={false} indentSize={indent}/>;
  };

  const columns = [
    {
      title: 'Policy Name',
      dataIndex: 'name',
      key: 'name',
    },
    {
      title: 'Premium Type',
      dataIndex: 'premium_type',
      key: 'premium_type',
    },
    {
      title: 'Policy No.',
      dataIndex: 'policy_no',
      key: 'policy_no',
    },
    {
      title: 'Plan Type',
      dataIndex: 'insurance_package',
      key: 'insurance_package',
    },
    {
      title: 'Enrollee Size',
      dataIndex: 'enrolee_size',
      key: 'enrolee_size',
    },
    {
      title: 'Active Enrollees',
      dataIndex: 'active_enrollees_count',
      key: 'active_enrollees_count',
    },
    {
      title: 'Date Joined',
      dataIndex: 'date',
      key: 'date',
    },
    {
      title: 'Status',
      key: 'status',
      render: (__, record) => record.status? <CheckOutlined style={{color: 'blue'}}/> : <CloseOutlined style={{color: 'red'}}/>,
    },
  ];

  const getAllEnrolees = () => {
    return orgData.map(da => da.enrollees).flat()
  }

  const getAllPolicies= () => {
    return orgData.map(da => {
      const daCopy = {...da}
      delete daCopy.enrollees
      return daCopy
    })
  }

  const downloadPolicies = () => {
    let data = getAllPolicies()
    const exemptedKeys = ['id', 'payment_id', 'additional_heads', 'disclaimer','association_id','flag_reason','flagged','key','paymentStatus','plans_id','status','update_required','updated']
    downloadCSVFromJSON(data, `${state.org_name.toLowerCase()} -- Policie`, exemptedKeys, () => console.log('Done'))
  }

  const downloadEnrolless = () => {
    const data = getAllEnrolees()
    const exemptedKeys = ['id', 'financier_id', 'flag_reason','flagged','key','status','update_required','updated', 'id_name', 'pic_name', ]
    downloadCSVFromJSON(data, `${state.org_name.toLowerCase()} -- Enrolsees`, exemptedKeys, () => console.log('Done'))
  }

  return (
    <>
      <Space style={{display: 'flex', justifyContent: 'space-between', alignItems: 'center', marginBottom: '2em'}}>
        <Button type="primary" onClick={() => navigate('/corporations')} icon={<ArrowLeftOutlined />} style={{marginTop: 20, display: 'flex', alignItems: 'center'}}>Back</Button>
        { state?.all_policies > 0 && <Button type="primary" onClick={downloadPolicies} icon={<UserOutlined />} style={{marginTop: 20, display: 'flex', alignItems: 'center'}}>Download Policies</Button>}
        { state?.all_enrolees > 0 && <Button type="primary" onClick={downloadEnrolless} icon={<TeamOutlined />} style={{marginTop: 20, display: 'flex', alignItems: 'center'}}>Download Enrollees</Button>}
      </Space>
      {
        state &&
        <Row  justify="start" alignItems="center" style={{marginTop: '2em', marginBottom: '2em', padding: 10, boxShadow: "0px 0px 10px 5px #3597d3", borderRadius: 5}}>
          <Col span={8} >
            <Row>
              <Col  span={24} style={{fontWeight: 'bolder'}}><u>Organization Name:</u></Col>
              <Col  span={24} style={{fontStyle: 'italic', fontWeight: 'bold', color: 'grey'}}>{state?.org_name}</Col>
            </Row>
          </Col>
          <Col span={8} >
            <Row>
              <Col  span={24} style={{fontWeight: 'bolder'}}><u>Organization State:</u></Col>
              <Col  span={24} style={{fontStyle: 'italic', fontWeight: 'bold', color: 'grey'}}>{state?.org_state}</Col>
            </Row>
          </Col>
          <Col span={8} >
            <Row>
              <Col  span={24} style={{fontWeight: 'bolder'}}><u>Organization City:</u></Col>
              <Col  span={24} style={{fontStyle: 'italic', fontWeight: 'bold', color: 'grey'}}>{state?.org_city}</Col>
            </Row>
          </Col>
          <Col span={8} >
            <Row>
              <Col  span={24} style={{fontWeight: 'bolder'}}><u>Organization Code:</u></Col>
              <Col  span={24} style={{fontStyle: 'italic', fontWeight: 'bold', color: 'grey'}}>{state?.org_code || state?.org_social_no}</Col>
            </Row>
          </Col>
          <Col span={8} >
            <Row>
              <Col  span={24} style={{fontWeight: 'bolder'}}><u>Member Since:</u></Col>
              <Col  span={24} style={{fontStyle: 'italic', fontWeight: 'bold', color: 'grey'}}>{state?.org_joined_date}</Col>
            </Row>
          </Col>
          <Col span={8} >
            <Row>
              <Col  span={24} style={{fontWeight: 'bolder'}}><u>Subscription Valid Till:</u></Col>
              <Col  span={24} style={{fontStyle: 'italic', fontWeight: 'bold', color: 'grey'}}>{state?.org_expiry_date}</Col>
            </Row>
          </Col>
          <Col span={8} >
            <Row>
              <Col  span={24} style={{fontWeight: 'bolder'}}><u>Number of Policies:</u></Col>
              <Col  span={24} style={{fontStyle: 'italic', fontWeight: 'bold', color: 'grey'}}>{state?.all_policies}</Col>
            </Row>
          </Col>
          <Col span={8} >
            <Row>
              <Col  span={24} style={{fontWeight: 'bolder'}}><u>Number of Active Policies:</u></Col>
              <Col  span={24} style={{fontStyle: 'italic', fontWeight: 'bold', color: 'grey'}}>{state?.active_policies}</Col>
            </Row>
          </Col>
          <Col span={8} >
            <Row>
              <Col  span={24} style={{fontWeight: 'bolder'}}><u>Number of Enrollees:</u></Col>
              <Col  span={24} style={{fontStyle: 'italic', fontWeight: 'bold', color: 'grey'}}>{state?.all_enrolees}</Col>
            </Row>
          </Col>
          <Col span={8} >
            <Row>
              <Col  span={24} style={{fontWeight: 'bolder'}}><u>Number of Active Enrollees:</u></Col>
              <Col  span={24} style={{fontStyle: 'italic', fontWeight: 'bold', color: 'grey'}}>{state?.active_enrollees}</Col>
            </Row>
          </Col>
        </Row> 
      }
      <>
        <Table columns={columns} dataSource={orgData.map((val, index) => {val['key'] = index.toString(); return val})} expandable={{
          // onExpand: (expanded, record) => console.log(expanded, record),
          indentSize: 0,
          expandedRowRender,
          defaultExpandedRowKeys: ['0']
        }}/>
      </>
    </>
  )
}

export default OrganizationDetail;