import { CheckOutlined, EditOutlined, LoadingOutlined, WarningOutlined } from "@ant-design/icons";
import { Button, Col, Input, Modal, Row, Select, notification } from "antd";
import React, { useEffect, useState } from "react";
import { packages, states, policy_type } from "../../assets/utils/config";
import lib from "../../assets/utils/lib";

export const PolicyHolder = ({ type, data }) => {
    const [show, setShow] = useState(false);
  
    const launch = (pol, type) => {
      setShow(true);
    };
  
    return (
      <>
        <Row
          style={{
            padding: "10px",
            borderBottom: "1px solid black",
            borderTop: "1px solid black",
            margin: "10px 0",
            cursor: "pointer",
          }}
          onClick={() => launch(data, "clients")}
        >
          <Col span={8}>
            <Row>
              <Col span={8}>Name: </Col>
              <Col span={16}>{data?.name}</Col>
            </Row>
          </Col>
          <Col span={10}>
            <Row>
              <Col span={10}>Insurance Package: </Col>
              <Col span={14}>{data?.insurance_package}</Col>
            </Row>
          </Col>
          <Col span={6}>
            <Row>
              <Col span={12}>Premium Type: </Col>
              <Col span={12}>{data?.premium_type?.toUpperCase()}</Col>
            </Row>
          </Col>
          <Col span={8}>
            <Row>
              <Col span={8}>Enrolee Size: </Col>
              <Col span={16}>{data?.enrolee_size}</Col>
            </Row>
          </Col>
          <Col span={10}>
            <Row>
              <Col span={10}>Email: </Col>
              <Col span={14}>{data?.email}</Col>
            </Row>
          </Col>
          <Col span={6}>
            <Row>
              <Col span={12}>Phone: </Col>
              <Col span={12}>{data?.phone}</Col>
            </Row>
          </Col>
          <Col span={8}>
            <Row>
              <Col span={8}>Policy Number: </Col>
              <Col span={16}>{data?.policy_no}</Col>
            </Row>
          </Col>
          <Col span={10}>
            <Row>
              <Col span={10}>Reg. Date: </Col>
              <Col span={14}>{data?.date}</Col>
            </Row>
          </Col>
          <Col span={6}>
            <Row>
              <Col span={12}>State / City: </Col>
              <Col span={12}>
                {data?.state?.toUpperCase()} / {data?.city?.toUpperCase()}
              </Col>
            </Row>
          </Col>
          {/* <Col span={2}>
            <Dropdown.Button overlay={menuProps}>Action</Dropdown.Button>
          </Col> */}
        </Row>
        <PolicyHolderModal
          type={type}
          data={data}
          display={show}
          action={() => setShow(false)}
        />
      </>
    );
  };

  const PolicyHolderModal = ({ data, display, action }) => {
    const [n_data, setNdata] = useState(data);
    const [edit, setEdit] = useState(false);
    const [, setUpdate] = useState(false);
    const [loadingUpdate, setLoadingUpdate] = useState(false);
    const [loadingFlag, setLoadingFlag] = useState(false);
    const [loadingDisable, setLoadingDisable] = useState(false);
    const [loadingActivate, setLoadingActivate] = useState(false);
    const [loadingRevoke, ] = useState(false);
  
    const [validatingSocial, setValidatingSocial] = useState(false);
  
    const [socialDetail, setSocialDetail] = useState(false);
  
    useEffect(() => {
      if (display) handleFocusOut();
    }, [display]); // eslint-disable-line react-hooks/exhaustive-deps
  
    useEffect(() => {
      if (n_data?.social_no) handleFocusOut();
    }, []); // eslint-disable-line react-hooks/exhaustive-deps
  
    useEffect(() => {
      const vn = Object.entries(n_data).filter((v, i) => v[1] !== data[v[0]]);
      if (vn.length) setUpdate(true);
    }, [n_data]); // eslint-disable-line react-hooks/exhaustive-deps
  
    const updateData = async (e, data) => {
      const targ = e;
      if (Number(targ) === 1) {
        setLoadingUpdate(true);
      } else if (Number(targ) === 2) {
        setLoadingActivate(true);
      } else if (Number(targ) === 3) {
        setLoadingDisable(true);
      } else if (Number(targ) === 4) {
        setLoadingFlag(true);
      } else if (Number(targ) === 5) {
        setLoadingFlag(true);
      }
  
      setEdit(false);
  
      lib
        .updateUserData({ ...n_data, ...data })
        .then((res) => res.data)
        .then((data) => {
          if (!data?.error) {
            notification.success({
              message: data?.message,
              placement: "topLeft",
            });
          } else {
            notification.error({
              message: data?.message,
              placement: "topLeft",
            });
          }
        })
        .catch((err) => {
          setEdit(true);
        })
        .finally(() => {
          if (Number(targ) === 1) {
            setLoadingUpdate(false);
          } else if (Number(targ) === 2) {
            setLoadingActivate(false);
          } else if (Number(targ) === 3) {
            setLoadingDisable(false);
          } else if (Number(targ) === 4) {
            setLoadingFlag(false);
          } else if (Number(targ) === 5) {
            setLoadingFlag(true);
          }
  
          setUpdate(false);
          action();
        });
    };
  
    const handleFocusOut = async (e) => {
      setValidatingSocial(true);
      let obj = {
        social_no: n_data?.social_no,
        mode: "corporate",
      };
  
      let reqData = await lib.validatSocial(obj);
  
      if (reqData.status === "error") {
        notification.error({ message: reqData.msg, placement: "topLeft" });
      }
  
      if (reqData?.error === 0) {
        setSocialDetail(reqData?.data);
      }
      setValidatingSocial(false);
    };
  
    const [updateCategory, setUpdateCategory] = useState(false);
    const [updateCategoryValidating, setUpdateCategoryValidating] =
      useState(false);
    const [categoryUpdateData, setCategoryUpdateData] = useState({});
    const [categoryVerified, setCategoryVerified] = useState(false);
    const [UpdateSocialDetail, setUpdateSocialDetail] = useState("");
  
    const updateUseCategory = () => {
      if (
        categoryUpdateData?.category === "self pay" &&
        categoryUpdateData?.social_no
      )
        categoryUpdateData.social_no = "";
  
      lib
        .updateUserData({ ...n_data, ...categoryUpdateData })
        .then((res) => res.data)
        .then((data) => {
          if (!data?.error) {
            notification.success({
              message: data?.message,
              placement: "topLeft",
            });
          } else {
            notification.error({
              message: data?.message,
              placement: "topLeft",
            });
          }
        })
        .catch((err) => {})
        .finally(() => {});
    };
  
    const verifyCode = async (e) => {
      setUpdateCategoryValidating(true);
      setCategoryVerified(false);
      let obj = {
        social_no: categoryUpdateData?.social_no || data?.social_no,
        mode: categoryUpdateData?.category || data?.category,
      };
  
      let reqData = await lib.validatSocial(obj);
  
      if (Number(reqData?.error) === 0) {
        setUpdateSocialDetail(reqData?.data);
        setSocialDetail(reqData?.data)
        console.log(reqData?.data);
        setCategoryVerified(true);
      } else {
        notification.error({
          message: reqData?.data?.message,
          placement: "topLeft",
        });
      }
      setValidatingSocial(false)
      setUpdateCategoryValidating(false);
    };
  
    return (
      <>
        <Modal
          title={
            <>
              User Data - {data?.policy_no} |{" "}
              <Button type="primary" danger={!Number(data?.status)}>
                {Number(data?.status) ? "Active" : "Inactive"}
              </Button>
            </>
          }
          centered
          open={display}
          onOk={action}
          onCancel={action}
          footer={
            <>
              {Number(data?.status) ? (
                <Button
                  type="primary"
                  disabled={
                    loadingUpdate ||
                    loadingFlag ||
                    loadingDisable ||
                    loadingActivate ||
                    loadingRevoke
                  }
                  style={{ backgroundColor: "grey", border: "1px solid red" }}
                  onClick={() => {
                    updateData(3, { status: 0 });
                  }}
                >
                  {loadingDisable ? <LoadingOutlined /> : <>Disable</>}
                </Button>
              ) : (
                <>
                  <Button
                    type="primary"
                    disabled={
                      loadingUpdate ||
                      loadingFlag ||
                      loadingDisable ||
                      loadingActivate ||
                      loadingRevoke
                    }
                    onClick={() =>
                      updateData(2, { status: 1, update_required: 0, paymentStatus: 1 })
                    }
                  >
                    {loadingActivate ? <LoadingOutlined /> : <>Activate</>}
                  </Button>
                  <Button
                    type="primary"
                    danger
                    disabled={
                      loadingUpdate ||
                      loadingFlag ||
                      loadingDisable ||
                      loadingActivate ||
                      loadingRevoke
                    }
                    onClick={() =>
                      updateData(5, { status: 0, update_required: 1, updated: 0 })
                    }
                  >
                    {loadingRevoke ? <LoadingOutlined /> : <>Revoke</>}
                  </Button>
                </>
              )}
              <Button
                type="dashed"
                disabled={
                  loadingUpdate ||
                  loadingFlag ||
                  loadingDisable ||
                  loadingActivate ||
                  loadingRevoke
                }
                danger
                onClick={action}
              >
                Close
              </Button>
            </>
          }
          width={800}
          destroyOnClose
        >
          <Row>
            <Col span={24}>
              <Row gutter={[16, 8]}>
                <Col span={24}>
                  <Row>
                    <Col span={8}>Name:</Col>
                    <Col span={16}>
                      <Input
                        disabled={!edit}
                        value={n_data?.name}
                        onChange={(e) =>
                          setNdata((d) => ({ ...d, name: e.target.value }))
                        }
                      />
                    </Col>
                  </Row>
                  <Row>
                    <Col span={8}>Insurance Package: </Col>
                    <Col span={16}>
                      <Select
                        disabled={!edit}
                        value={n_data?.insurance_package}
                        onChange={(e) =>
                          setNdata((d) => ({ ...d, insurance_package: e }))
                        }
                        options={packages}
                        style={{ width: "100%" }}
                      />
                    </Col>
                  </Row>
                  <Row>
                    <Col span={8}>Premium Type:</Col>
                    <Col span={16}>
                      <Select
                        disabled={!edit}
                        value={n_data?.premium_type}
                        onChange={(e) =>
                          setNdata((d) => ({ ...d, premium_type: e }))
                        }
                        options={policy_type}
                        style={{ width: "100%" }}
                      />
                    </Col>
                  </Row>
                  <Row>
                    <Col span={8}>Phone:</Col>
                    <Col span={16}>
                      <Input
                        disabled
                        value={n_data?.phone}
                        onChange={(e) =>
                          setNdata((d) => ({ ...d, phone: e.target.value }))
                        }
                      />
                    </Col>
                  </Row>
                  <Row>
                    <Col span={8}>Policy No.:</Col>
                    <Col span={16}>
                      <Input
                        disabled
                        value={n_data?.policy_no}
                        onChange={(e) =>
                          setNdata((d) => ({ ...d, policy_no: e.target.value }))
                        }
                      />
                    </Col>
                  </Row>
                  <Row>
                    <Col span={8}>Premium Category:</Col>
                    <Col span={12}>
                      <Input disabled value={n_data?.category} />
                    </Col>
                    <Col span={4}>
                      <Button
                        type="dashed"
                        danger
                        onClick={() => setUpdateCategory(true)}
                        style={{ width: "100%" }}
                      >
                        Change
                      </Button>
                    </Col>
                  </Row>
                  {data?.social_no && data?.category === "corporate" ? (
                    <Row>
                      <Col span={8}>Association Code:</Col>
                      <Col span={16}>
                        <Input
                          disabled
                          value={socialDetail?.org_name || data?.social_no}
                          onChange={(e) =>
                            setNdata((d) => ({ ...d, phone: e.target.value }))
                          }
                          suffix={
                            validatingSocial && !socialDetail ? (
                              <LoadingOutlined style={{ color: "blue" }} />
                            ) : !validatingSocial && socialDetail ? (
                              <CheckOutlined style={{ color: "green" }} />
                            ) : (
                              <WarningOutlined style={{ color: "gold" }} />
                            )
                          }
                        />
                      </Col>
                    </Row>
                  ) : null}
                  <Row>
                    <Col span={8}>Enrolee Size:</Col>
                    <Col span={16}>
                      <Input
                        disabled={!edit}
                        value={n_data?.enrolee_size}
                        onChange={(e) =>
                          setNdata((d) => ({
                            ...d,
                            enrolee_size: e.target.value,
                          }))
                        }
                      />
                    </Col>
                  </Row>
                  <Row>
                    <Col span={8}>Email:</Col>
                    <Col span={16}>
                      <Input
                        disabled
                        value={n_data?.email}
                        onChange={(e) =>
                          setNdata((d) => ({ ...d, email: e.target.value }))
                        }
                        rows={3}
                      />
                    </Col>
                  </Row>
                  <Row>
                    <Col span={8}>State:</Col>
                    <Col span={16}>
                      <Select
                        disabled={!edit}
                        value={n_data?.state}
                        onChange={(e) => setNdata((d) => ({ ...d, state: e }))}
                        options={states}
                        style={{ width: "100%" }}
                      />
                    </Col>
                  </Row>
                  <Row>
                    <Col span={8}>City:</Col>
                    <Col span={16}>
                      <Input
                        disabled={!edit}
                        value={n_data?.city}
                        onChange={(e) => {
                          setNdata((d) => ({ ...d, city: e.target.value }));
                        }}
                      />
                    </Col>
                  </Row>
                  <Row>
                    <Col span={8}>Regitration. Date:</Col>
                    <Col span={16}>
                      <Input
                        disabled
                        value={n_data?.date}
                        onChange={(e) =>
                          setNdata((d) => ({ ...d, date: e.target.value }))
                        }
                      />
                    </Col>
                  </Row>
                  <Row>
                    <Col span={8}>Expiration Date:</Col>
                    <Col span={16}>
                      <Input
                        disabled
                        value={n_data?.expiry_date}
                        onChange={(e) =>
                          setNdata((d) => ({ ...d, expiry_date: e.target.value }))
                        }
                      />
                    </Col>
                  </Row>
                </Col>
                <Col span={24}>
                  <Row justify="end" gutter={[16, 16]}>
                    <Col style={{ textAlign: "right", alignContent: "flex-end" }}>
                      {edit ? (
                        <CheckOutlined
                          onClick={() => setEdit(false)}
                          style={{ fontSize: 30, color: "blue" }}
                          title="Done"
                        />
                      ) : (
                        <EditOutlined
                          onClick={() => setEdit(true)}
                          style={{ fontSize: 30, color: "blue" }}
                        />
                      )}
                    </Col>
                  </Row>
                </Col>
              </Row>
            </Col>
          </Row>
        </Modal>

        {/* Social Code Update Modal */}
        <Modal
          title="Plan Category Edit"
          open={updateCategory}
          onOk={updateUseCategory}
          onCancel={() => setUpdateCategory(false)}
          destroyOnClose
          footer={[
            <Button
              type="primary"
              onClick={updateUseCategory}
              disabled={!categoryVerified}
            >
              Update
            </Button>,
            <Button
              type="primary"
              danger
              onClick={() => setUpdateCategory(false)}
            >
              Close
            </Button>,
          ]}
        >
          <Row gutter={[12, 12]}>
            <Col span={6}>Policy Category</Col>
            <Col span={18}>
              <Select
                value={categoryUpdateData?.category || n_data?.category}
                onChange={(e) =>
                  setCategoryUpdateData((d) => ({ ...d, category: e }))
                }
                options={[
                  { label: "Self Pay", value: "self pay" },
                  { label: "Corporate", value: "corporate" },
                ]}
                style={{ width: "100%" }}
              />
            </Col>
            {(categoryUpdateData?.category === "corporate" ||
              n_data?.category === "corporate") && (
              <>
                <Col span={6}>Assocaition Code</Col>
                <Col span={14}>
                  <Input
                    value={categoryUpdateData?.social_no || n_data?.social_no}
                    onChange={(e) =>
                      setCategoryUpdateData((d) => ({
                        ...d,
                        social_no: e.target.value,
                      }))
                    }
                    style={{ width: "100%" }}
                  />
                </Col>
                <Col span={4}>
                  <Button
                    type="dashed"
                    danger
                    onClick={verifyCode}
                    style={{ width: "100%" }}
                    disabled={updateCategoryValidating}
                  >
                    verify
                  </Button>
                </Col>
                <Col span={6}></Col>
                <Col span={18}>{UpdateSocialDetail?.org_name}</Col>
              </>
            )}
          </Row>
        </Modal>
      </>
    );
  };
