const formValidator = {}

const validateEmail = (email) => {
   return email.match(/^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/);
};

formValidator.validateAccountRegistration = (form, builder, setError) => {
   setError("")

   // Category
   if (!form.category) {
      return setError("Category is required")
   }
   builder.category = form.category
   // builder.category = 'individual'

   // Premium
   if (!form.premium_type) {
      return setError("Premium Type is required")
   }
   builder.premium_type = form.premium_type

   builder.policy_cost = form.policy_cost

   // Association code
   if (!form.social_no && form.category && form.category === 'corporate') {
      return setError("Social Number is required")
   }
   builder.social_no = form.social_no ?? ''
   // builder.social_no = 'AMANAPTITUDE'

   builder.enrolee_size = form.enrolee_size
   // builder.enrolee_size = 1

   if(!form.insurance_package){
      return setError("Insurance Package Cannot Be Empty")
   }
   builder.insurance_package = form.insurance_package

   // Name
   if (!form.name) {
      return setError("Full Name is required")
   }
   builder.name = form.name

   // Email
   if (!form.email) {
      return setError("Email is required")
   }
   if (!validateEmail(form.email)) {
      return setError("Invalid Email Input")
   }
   builder.email = form.email

   // Email
   if (form.email !== form.confirm_email) {
      return setError("Email does not match")
   }

   // builder.additional_heads = 0
   builder.additional_heads = form.additional_heads || 0

   if (!form.state) {
      return setError("state is requred")
   }

   builder.state = form.state;

   if (!form.city) {
      return setError("city is required")
   }

   builder.city = form.city;

   //validate the phone
   if (!form.phone) {
      return setError("phone number is required")
   }
   if (!/^[0-9]+$/.test(form.phone)) {
      return setError("Phone number should be digits only")
   }
   if (!/^0/.test(form.phone)) {
      return setError("Phone number must start with zero. e.g (070........)")
   }
   if (form.phone.length !== 11) {
      return setError("Invalid phone number. Phone number expects 11 digits")
   }
   builder.phone = form.phone

    // Email
    if (form.phone !== form.confirm_phone) {
      return setError("Phone number does not match")
   }
   


   // validate password
   if (!form.password) {
      return setError("Password is required")
   }
   if (form.confirm_password !== form.password) {
      return setError("Confirm Password Mismatch")
   } else {
      builder.password = form.password
   }

   // disclaimer
   builder.disclaimer = 1;

   return builder
}



// validate Principal form
formValidator.validateDependent = (form, builder, setError) => {
   setError("");

   // First name 
   if (!form.first_name) {
      return setError("First Name is required")
   }
   builder.first_name = form.first_name

   // Middle name
   // if (!form.middle_name) {
   //    return setError("Middle Name is required")
   // }
   builder.middle_name = form.middle_name

   // Last name
   if (!form.surname) {
      return setError("Surname is required")
   }
   builder.surname = form.surname

   // State
   if (!form.state) {
      return setError("state is required")
   }
   builder.state = form.state

   // LGA
   if (!form.lga) {
      return setError("lga is required")
   }
   builder.lga = form.lga

   // DOB
   if (!form.date_of_birth) {
      return setError("date of birth is required")
   }
   builder.date_of_birth = form.date_of_birth

   // Gender
   if (!form.state) {
      return setError("state is required")
   }
   builder.gender = form.gender

   // Religion
   // if (!form.state) {
   //    return setError("state is required")
   // }
   builder.religion = form.religion

   // Marital Status
   if (!form.marital_status) {
      return setError("marital status is required")
   }
   builder.marital_status = form.marital_status

   // Job title
   if (!form.job_title) {
      return setError("job title is required")
   }
   builder.job_title = form.job_title

   // Primary Health Care Facility
   if (!form.primary_health_facility) {
      return setError("primary health facility is required")
   }
   builder.primary_health_facility = form.primary_health_facility

   // Secondary Health Care Facility
   if (!form.secondary_health_facility) {
      return setError("secondary health facility is required")
   }
   builder.secondary_health_facility = form.secondary_health_facility

   // Contact Address
   if (!form.contact_address) {
      return setError("contact address is required")
   }
   builder.contact_address = form.contact_address

   // Existing Medical Condition
   if (!form.existing_medical_condition) {
      return setError("existing medical condition is required")
   }
   builder.existing_medical_condition = form.existing_medical_condition

   // Previous Medical Condition
   if (!form.previous_medical_condition) {
      return setError("previous medical condition is required")
   }
   builder.previous_medical_condition = form.previous_medical_condition

   // return payload
   return builder
}


export default formValidator