import {
  Button,
  Input,
  Select,
  notification,
  Row,
  Col,
  Avatar,
  Modal,
  Empty,
  FloatButton,
  Badge,
  Radio,
  DatePicker,
  Divider,
  Spin,
} from "antd";
import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { useAuth } from "../../assets/utils/useAuth";
import useLocalStorage from "../../assets/utils/useLocal";
import lib, { calculateAgeAlt, myRequestObj } from "../../assets/utils/lib";
import "./clients.css";
import { uri_img } from "../../assets/utils/http-request";
import config, { category, facilityType, gender, packagePrices, packages, policy_type, role, searchTarget, states, title } from "../../assets/utils/config";
import {
  AppstoreAddOutlined,
  CheckOutlined,
  CloseOutlined,
  EditOutlined,
  LoadingOutlined,
  SearchOutlined,
  UserAddOutlined,
  UsergroupAddOutlined,
  WarningOutlined,
} from "@ant-design/icons";
import { BiBuildings } from "react-icons/bi";

import { NullSafe } from "../../assets/utils/Helpers";
import formValidator from "../formvalidation";
import UploadImage from "../compoinents/PictureUpload";

import { CareCard } from "../compoinents/idCard.js";
import InputCopy from "../compoinents/copyInput.js";

const { TextArea } = Input;
const { Ribbon } = Badge;
const { Option } = Select;

const ClientVerufyHook = (props) => {
  const { user } = useAuth();
  const navigate = useNavigate();

  const [, setError] = React.useState(false);
  const [socialwarn, setSocialWarn] = useState("");
  const [validSocial, setValidSocial] = useState(true);
  const [addedHeads, setAddedHeads] = useState(0);
  const [premiumType, setPremiumType] = useState("Individual");

  const [clientRegistrationData, setClientRegistrationData] = useState({});
  const [facilityRegistrationData, setFacilityRegistrationData] = useState({
    facility_staff_id: user?.staffId,
  });

  const [, setShowDetailFlag] = useState(false);
  const [loading, setLoading] = useState(false);
  const [loadingCreateUser, setLoadingCreateUser] = useState(false);
  const [loadingCreateFacility, setLoadingCreateFacility] = useState(false);
  const [showCreateUserModal, setShowCreateUserModal] = useState(false);
  const [showCreateFacilityModal, setShowCreateFacilityModal] = useState(false);
  const [validatingSocial, setValidatingSocial] = useState(false);
  const [validatedSocial, setValidatedSocial] = useState(false);

  const [clientDate, setClientData] = useState({});
  const [clientSearch, setClientSearch] = useLocalStorage(config.key.search);
  const [facilityDate, setFacilityDate] = useState({});
  const [clientSearchTarget, setClientSearchTarget] = useLocalStorage(
    config.key.searchTarget
  );

  const [previousSearchTerm, setPreviousSearchTerm] = useState(clientSearch);

  useEffect(() => {
    if (!user) {
      navigate("/login");
    }
  });

  useEffect(() => {
    showDetail();
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  const showDetail = async () => {
    if (clientSearchTarget === "clients") {
      setShowDetailFlag(false);
      setClientData({});

      const key = "verify";

      if (!clientSearch?.search) return;

      setLoading(true);

      notification.info({
        message: "Loading...",
        key,
        icon: <LoadingOutlined />,
        duration: 0,
        placement: "topLeft",
      });

      let reqData = await lib.search(clientSearch);

      if (reqData?.status === "error") {
        notification.error({
          message: reqData?.msg,
          key,
          duration: 3,
          placement: "topLeft",
        });
      }

      if (!reqData?.error) {
        setShowDetailFlag(true);
        notification.success({
          message: reqData?.data?.message,
          key,
          duration: 2,
          placement: "topLeft",
        });
        setClientData(reqData?.data?.data);
      }

      if (reqData?.error) {
        notification.error({
          message: reqData?.data?.message,
          key,
          duration: 2,
          placement: "topLeft",
        });
      }
      setLoading(false);
    } else if (clientSearchTarget === "facilities") {
      setShowDetailFlag(false);
      setFacilityDate({});

      const key = "verify";

      if (!clientSearch?.search) return;

      setLoading(true);

      notification.info({
        message: "Loading...",
        key,
        icon: <LoadingOutlined />,
        duration: 0,
        placement: "topLeft",
      });

      let reqData = await lib.searchProviders(clientSearch);

      if (reqData?.status === "error") {
        notification.error({
          message: reqData?.msg,
          key,
          duration: 3,
          placement: "topLeft",
        });
      }

      if (!reqData?.error) {
        setShowDetailFlag(true);
        notification.success({
          message: reqData?.data?.message,
          key,
          duration: 2,
          placement: "topLeft",
        });
        setFacilityDate(reqData?.data?.data);
      }

      if (reqData?.error) {
        notification.error({
          message: reqData?.data?.message,
          key,
          duration: 2,
          placement: "topLeft",
        });
      }

      setLoading(false);
    }
    setPreviousSearchTerm(clientSearch);
  };

  const saveProvider = async () => {
    if (
      !facilityRegistrationData?.facility_name ||
      !facilityRegistrationData?.facility_type ||
      !facilityRegistrationData?.facility_plan_type ||
      !facilityRegistrationData?.facility_email ||
      !facilityRegistrationData?.facility_phone ||
      !facilityRegistrationData?.facility_state ||
      !facilityRegistrationData?.facility_city
    ) {
      notification.error({
        message: "Please fill the compulsory fields",
        placement: "topLeft",
      });
      return;
    }

    setLoadingCreateFacility(true);

    let reqData = await lib.saveProvider(facilityRegistrationData);

    if (reqData?.status === "error") {
      notification.error({ message: reqData?.msg, placement: "topLeft" });
    }

    if (reqData.error === 1) {
      notification.error({ message: reqData?.message, placement: "topLeft" });
    }

    if (reqData?.error === 0) {
      notification.success({
        message: reqData?.message,
        duration: 5,
        placement: "topLeft",
      });
      setShowCreateFacilityModal(false);
      setFacilityRegistrationData({});
    }

    setLoadingCreateFacility(false);
  };

  const handleOk = async () => {
    if (
      (clientRegistrationData.category === "corporate" ||
        clientRegistrationData.category === "referal") &&
      validSocial === false
    ) {
      notification.error({
        message: "Invalid Assocaition Code",
        placement: "topLeft",
      });
      return;
    }

    if (clientRegistrationData?.premium_type === "family") {
      var additional_heads = 6 + addedHeads - 1;
      var enrolee_size = 6 + addedHeads;
      var policy_cost = clientRegistrationData?.insurance_package
        ? packagePrices[clientRegistrationData?.insurance_package][
            "individual"
          ] *
            6 +
          packagePrices[clientRegistrationData?.insurance_package][
            "individual"
          ] *
            addedHeads
        : packagePrices[clientRegistrationData?.insurance_package]["family"] +
          packagePrices[clientRegistrationData?.insurance_package][
            "individual"
          ] *
            addedHeads;
    } else {
      additional_heads = addedHeads;
      enrolee_size = addedHeads + 1;
      policy_cost =
        packagePrices[clientRegistrationData?.insurance_package]["individual"] +
        packagePrices[clientRegistrationData?.insurance_package]["individual"] *
          addedHeads;
    }

    let builder = formValidator.validateAccountRegistration(
      {
        ...clientRegistrationData,
        additional_heads,
        enrolee_size,
        policy_cost,
      },
      {},
      setError
    );

    setLoadingCreateUser(true);

    let reqData = await lib.registerDash(builder);

    if (reqData.status === "error") {
      notification.error({ message: reqData.msg, placement: "topLeft" });
    }
    if (reqData.data?.error === 1) {
      notification.error({
        message: reqData.data.message,
        placement: "topLeft",
      });
    }

    if (reqData.data?.error === 0) {
      notification.success({
        message: reqData.data.message,
        placement: "topLeft",
      });
      setShowCreateUserModal(false);
      clientRegistrationData({});
    }

    setLoadingCreateUser(false);
  };

  const handleDependent = (e) => {
    // console.log(e)
    setAddedHeads(e);
  };

  const handleFocusOut = async (e) => {
    setValidatingSocial(true);
    let obj = {
      social_no: clientRegistrationData?.social_no,
      mode: clientRegistrationData?.category,
    };

    let reqData = await lib.validatSocial(obj);

    if (reqData.status === "error") {
      notification.error({ message: reqData.msg, placement: "topLeft" });
    }

    if (reqData?.error === 1) {
      notification.error({ message: "Invalid code", placement: "topLeft" });
      setSocialWarn("Invalid code");
      setValidSocial(false);
    }

    if (reqData?.error === 0) {
      notification.success({ message: reqData.message, placement: "topLeft" });
      setValidSocial(true);
      setSocialWarn("");
    }
    setValidatedSocial(true);
    setValidatingSocial(false);
  };

  return (
    <>
      {user ? (
        <Row
          gutter={[16, 24]}
          style={{ marginTop: 10, padding: "0 auto !important" }}
        >
          <Col span={24}>
            <Row
              justify="middle"
              justifySelf="middle"
              style={{ width: "100%" }}
            >
              <Col span={20}>
                <Input
                  id="form7Example1"
                  value={clientSearch?.search}
                  class="form-control rounded"
                  onChange={(e) => setClientSearch({ search: e.target.value })}
                  placeholder="Enter Search Keyword..."
                  onPressEnter={showDetail}
                />
              </Col>
              <Col span={3}>
                <Select
                  options={searchTarget}
                  style={{ width: "100%" }}
                  value={clientSearchTarget}
                  onChange={(e) => {
                    setClientSearchTarget(e);
                  }}
                ></Select>
              </Col>
              <Col span={1}>
                <Button type="primary" onClick={showDetail}>
                  <SearchOutlined title="Search Client" />
                </Button>
              </Col>
            </Row>
          </Col>
          {clientSearchTarget === "clients" ? (
            clientDate?.clients?.length ||
            clientDate?.client_biodata?.length ? (
              <>
                {!loading ? (
                  <Col span={24}>
                    <h5 style={{ textAlign: "center" }}>Policy Accounts</h5>
                    {clientDate?.clients?.map((pol) => (
                      <Ribbon
                        placement="end"
                        text={
                          pol?.status ? (
                            <CheckOutlined color="white" />
                          ) : (
                            <CloseOutlined color="white" />
                          )
                        }
                        color={pol?.status ? "#1890ff" : "#ff7a45"}
                      >
                        <Ribbon
                          placement="start"
                          text={pol?.category}
                          color={"#520339"}
                        >
                          <SearchDataBio type="clients" data={pol} action = {showDetail}/>
                        </Ribbon>
                      </Ribbon>
                    ))}
                    <h5 style={{ textAlign: "center", marginTop: "20px" }}>
                      Enroless Biodata
                    </h5>
                    {clientDate?.client_biodata?.map((bio) => (
                      <Ribbon
                        placement="end"
                        text={
                          bio?.status ? (
                            <CheckOutlined color="white" />
                          ) : (
                            <CloseOutlined color="white" />
                          )
                        }
                        color={bio?.status ? "#1890ff" : "#ff7a45"}
                      >
                        <SearchDataBio type="client_biodata" data={bio} action = {showDetail}/>
                      </Ribbon>
                    ))}
                  </Col>
                ) : (
                  <div
                    style={{
                      width: "100%",
                      display: "flex",
                      justifyContent: "center",
                    }}
                  >
                    <p>
                      <LoadingOutlined />
                    </p>
                  </div>
                )}
              </>
            ) : (
              <Empty />
            )
          ) : (
            <>
              {facilityDate?.length ? (
                <>
                  {!loading ? (
                    <Col span={24}>
                      <h5 style={{ textAlign: "center" }}>
                        Matching Facilities for '{previousSearchTerm?.search}'
                        Keyword -- {facilityDate?.length}{" "}
                      </h5>
                      {facilityDate?.map((fac) => (
                        <Ribbon
                          placement="end"
                          text={
                            fac?.facility_status ? (
                              <CheckOutlined color="white" />
                            ) : (
                              <CloseOutlined color="white" />
                            )
                          }
                          color={fac?.facility_status ? "#1890ff" : "#ff7a45"}
                        >
                          <SearchDataBio type="facility" data={fac} action = {showDetail}/>
                        </Ribbon>
                      ))}
                    </Col>
                  ) : (
                    <div
                      style={{
                        width: "100%",
                        display: "flex",
                        justifyContent: "center",
                      }}
                    >
                      <p>
                        <LoadingOutlined />
                      </p>
                    </div>
                  )}
                </>
              ) : (
                <Empty />
              )}
            </>
          )}
        </Row>
      ) : null}
      {user && [1, 2].includes(Number(user.role["staffRoleId"])) ? (
      <FloatButton.Group
        icon={<AppstoreAddOutlined />}
        type="primary"
        trigger="hover"
      >
          <FloatButton
            icon={<UserAddOutlined />}
            onClick={() => setShowCreateUserModal(true)}
          />
        <FloatButton
          icon={<BiBuildings />}
          onClick={() => setShowCreateFacilityModal(true)}
        />
      </FloatButton.Group> ) : null}

      {/* Clients account creation modal */}
      <Modal
        title={
          <Row>
            <Col>Create Policy Account</Col>
          </Row>
        }
        centered
        open={showCreateUserModal && !showCreateFacilityModal}
        onOk={() => {}}
        onCancel={() => setShowCreateUserModal(false)}
        footer={
          user && [1, 2].includes(Number(user.role["staffRoleId"])) ? (
          <>
            <Button disabled={loadingCreateUser} onClick={handleOk}>
              {loadingCreateUser ? <LoadingOutlined /> : <>Create Account</>}
            </Button>
            <Button
              type="dashed"
              disabled={loadingCreateUser}
              danger
              onClick={() => setShowCreateUserModal(false)}
            >
              Close
            </Button>
          </>) : null
        }
        width={800}
        // destroyOnClose
      >
        <Row>
          <Col span={24}>
            <Row gutter={[16, 8]}>
              <Col span={24}>
                <Row>
                  <Col span={8}>Insurance Package:</Col>
                  <Col span={16}>
                    <Select
                      disabled={loadingCreateUser}
                      onChange={(e) =>
                        setClientRegistrationData((d) => ({
                          ...d,
                          insurance_package: e,
                        }))
                      }
                      value={clientRegistrationData?.insurance_package}
                      style={{ width: "100%", marginRight: "10px" }}
                      options={packages}
                    />
                  </Col>
                </Row>
                <Row>
                  <Col span={8}>Policy Type: </Col>
                  <Col span={16}>
                    <Select
                      disabled={loadingCreateUser}
                      onChange={(e) => {
                        setPremiumType(e);
                        setClientRegistrationData((d) => ({
                          ...d,
                          premium_type: e,
                        }));
                      }}
                      value={
                        clientRegistrationData?.premium_type || premiumType
                      }
                      style={{ width: "100%", marginRight: "10px" }}
                      options={policy_type}
                    />
                  </Col>
                </Row>
                <Row>
                  <Col span={8}>Plan Category: </Col>
                  <Col span={16}>
                    <Select
                      disabled={loadingCreateUser}
                      onChange={(e) =>
                        setClientRegistrationData((d) => ({
                          ...d,
                          category: e,
                        }))
                      }
                      value={clientRegistrationData?.category}
                      style={{ width: "100%", marginRight: "10px" }}
                      options={category}
                    />
                  </Col>
                </Row>
                {clientRegistrationData.category === "corporate" ||
                clientRegistrationData.category === "referal" ? (
                  clientRegistrationData.category === "corporate" ? (
                    <Row>
                      <Col span={8}>Association Code:</Col>
                      <Col span={16}>
                        <Input
                          disabled={loadingCreateUser}
                          suffix={
                            !validSocial && validatedSocial ? (
                              <CloseOutlined style={{ color: "red" }} />
                            ) : validatingSocial ? (
                              <LoadingOutlined style={{ color: "blue" }} />
                            ) : validSocial && validatedSocial ? (
                              <CheckOutlined style={{ color: "green" }} />
                            ) : null
                          }
                          onBlur={handleFocusOut}
                          onChange={(e) =>
                            setClientRegistrationData((d) => ({
                              ...d,
                              social_no: e.target.value,
                            }))
                          }
                          value={clientRegistrationData?.social_no}
                          placeholder="23SWIWQQ"
                          style={{ width: "100%", marginRight: "10px" }}
                        />
                        <span>
                          {clientRegistrationData.category === "corporate" &&
                          !validSocial ? (
                            <>{socialwarn}</>
                          ) : null}
                        </span>
                      </Col>
                    </Row>
                  ) : (
                    <Row>
                      <Col span={8}>Referal Code:</Col>
                      <Col span={16}>
                        <Input
                          disabled={loadingCreateUser}
                          suffix={
                            !validSocial && validatedSocial ? (
                              <CloseOutlined color="red" />
                            ) : validatingSocial ? (
                              <LoadingOutlined color="blue" />
                            ) : !validSocial && validatedSocial ? (
                              <CheckOutlined color="green" />
                            ) : null
                          }
                          onBlur={handleFocusOut}
                          onChange={(e) =>
                            setClientRegistrationData((d) => ({
                              ...d,
                              social_no: e.target.value,
                            }))
                          }
                          value={clientRegistrationData?.social_no}
                          placeholder="23SWIWQQ"
                          style={{ width: "100%", marginRight: "10px" }}
                        />
                        <span>
                          {clientRegistrationData.category === "referal" &&
                          !validSocial ? (
                            <>{socialwarn}</>
                          ) : null}
                        </span>
                      </Col>
                    </Row>
                  )
                ) : null}
                <Row>
                  <Col span={8}>Full Name:</Col>
                  <Col span={16}>
                    <Input
                      disabled={loadingCreateUser}
                      onChange={(e) =>
                        setClientRegistrationData((d) => ({
                          ...d,
                          name: e.target.value,
                        }))
                      }
                      autoFocus
                      value={clientRegistrationData?.name}
                      placeholder="Musa Umar"
                      style={{ width: "100%", marginRight: "10px" }}
                    />
                  </Col>
                </Row>
                <Row>
                  <Col span={8}>Additional Dependents:</Col>
                  <Col span={16}>
                    <Select
                      disabled={loadingCreateUser}
                      style={{ width: "100%", marginRight: "10px" }}
                      onChange={handleDependent}
                      value={addedHeads}
                    >
                      <Option value={0}>0</Option>
                      <Option value={1}>1</Option>
                      <Option value={2}>2</Option>
                      <Option value={3}>3</Option>
                      <Option value={4}>4</Option>
                      <Option value={5}>5</Option>
                      <Option value={6}>6</Option>
                      <Option value={7}>7</Option>
                      <Option value={8}>8</Option>
                      <Option value={9}>9</Option>
                      <Option value={10}>10</Option>
                    </Select>
                    <span
                      style={{
                        fontSize: "10px",
                        fontWeight: "bolder",
                        color: "skyblue",
                        lineHeight: "1px",
                      }}
                    >
                      Use this if you have additional enrollees apart from the
                      provisonal enrollee size: Family = 6 Enroless; Individual
                      = 1 Enrolee
                    </span>
                  </Col>
                </Row>
                <Row>
                  <Col span={8}>State of Residence: </Col>
                  <Col span={16}>
                    <Select
                      disabled={loadingCreateUser}
                      style={{ width: "100%", marginRight: "10px" }}
                      onChange={(e) => {
                        setClientRegistrationData((d) => ({ ...d, state: e }));
                      }}
                      value={clientRegistrationData?.state}
                      options={states}
                    />
                  </Col>
                </Row>
                <Row>
                  <Col span={8}>City of Residence: </Col>
                  <Col span={16}>
                    <Input
                      disabled={loadingCreateUser}
                      onChange={(e) =>
                        setClientRegistrationData((d) => ({
                          ...d,
                          city: e.target.value,
                        }))
                      }
                      value={clientRegistrationData?.city}
                      placeholder="Ogidilu"
                      style={{ width: "100%", marginRight: "10px" }}
                    />
                  </Col>
                </Row>
                <Row>
                  <Col span={8}>Email:</Col>
                  <Col span={16}>
                    <Input
                      disabled={loadingCreateUser}
                      type="email"
                      onChange={(e) =>
                        setClientRegistrationData((d) => ({
                          ...d,
                          email: e.target.value,
                        }))
                      }
                      value={clientRegistrationData?.email}
                      placeholder="example@gmail.com"
                      style={{ width: "100%", marginRight: "10px" }}
                    />
                  </Col>
                </Row>
                <Row>
                  <Col span={8}>Confirm Email:</Col>
                  <Col span={16}>
                    <Input
                      disabled={loadingCreateUser}
                      type="email"
                      onChange={(e) =>
                        setClientRegistrationData((d) => ({
                          ...d,
                          confirm_email: e.target.value,
                        }))
                      }
                      value={clientRegistrationData?.confirm_email}
                      placeholder="example@gmail.com"
                      style={{ width: "100%", marginRight: "10px" }}
                    />
                  </Col>
                </Row>
                <Row>
                  <Col span={8}>Phone Number:</Col>
                  <Col span={16}>
                    <Input
                      disabled={loadingCreateUser}
                      type="phone"
                      onChange={(e) =>
                        setClientRegistrationData((d) => ({
                          ...d,
                          phone: e.target.value,
                        }))
                      }
                      value={clientRegistrationData?.phone}
                      placeholder="0801 234 5678"
                      style={{ width: "100%", marginRight: "10px" }}
                    />
                  </Col>
                </Row>
                <Row>
                  <Col span={8}>Confirm Phone Number</Col>
                  <Col span={16}>
                    <Input
                      disabled={loadingCreateUser}
                      type="phone"
                      onChange={(e) =>
                        setClientRegistrationData((d) => ({
                          ...d,
                          confirm_phone: e.target.value,
                        }))
                      }
                      value={clientRegistrationData?.confirm_phone}
                      placeholder="0801 234 5678"
                      style={{ width: "100%", marginRight: "10px" }}
                    />
                  </Col>
                </Row>
                <Row>
                  <Col span={8}>Password:</Col>
                  <Col span={16}>
                    <Input.Password
                      disabled={loadingCreateUser}
                      placeholder="*********"
                      onChange={(e) =>
                        setClientRegistrationData((d) => ({
                          ...d,
                          password: e.target.value,
                        }))
                      }
                      value={clientRegistrationData?.password}
                      style={{ width: "100%", marginRight: "10px" }}
                    />
                  </Col>
                </Row>
                <Row>
                  <Col span={8}>Confirm Password:</Col>
                  <Col span={16}>
                    <Input.Password
                      disabled={loadingCreateUser}
                      onChange={(e) =>
                        setClientRegistrationData((d) => ({
                          ...d,
                          confirm_password: e.target.value,
                        }))
                      }
                      value={clientRegistrationData?.confirm_password}
                      placeholder="*********"
                      style={{ width: "100%", marginRight: "10px" }}
                    />
                  </Col>
                </Row>
              </Col>
            </Row>
          </Col>
        </Row>
      </Modal>

      {/* CFacility Onboarding Modal */}
      <Modal
        title="Onboard New Facility"
        centered
        open={showCreateFacilityModal && !showCreateUserModal}
        onOk={() => {}}
        onCancel={() => setShowCreateFacilityModal(false)}
        footer={
          user && [1, 2].includes(Number(user.role["staffRoleId"])) ? (
          <>
            <Button disabled={loadingCreateFacility} onClick={saveProvider}>
              {loadingCreateFacility ? <LoadingOutlined /> : <>Add Facility</>}
            </Button>
            <Button
              type="dashed"
              disabled={loadingCreateFacility}
              danger
              onClick={() => setShowCreateFacilityModal(false)}
            >
              Close
            </Button>
          </>) : null
        }
        width={800}
        destroyOnClose
      >
        <Row>
          <Col span={24}>
            <Row gutter={[16, 8]}>
              <Col span={24}>
                <Row>
                  <Col span={8}>
                    Facility Name <span style={{ color: "red" }}>*</span>:{" "}
                  </Col>
                  <Col span={16}>
                    <Input
                      disabled={loadingCreateFacility}
                      autoFocus
                      onChange={(e) =>
                        setFacilityRegistrationData((d) => ({
                          ...d,
                          facility_name: e.target.value,
                        }))
                      }
                      value={facilityRegistrationData?.facility_name}
                      style={{ width: "100%" }}
                      placeholder="What is the full name of the facilty?"
                    />
                    <span
                      style={{
                        fontSize: "10px",
                        fontWeight: "bolder",
                        color: "skyblue",
                      }}
                    >
                      Kindly include the branch city or area if its a branch of
                      an already onboarded facility
                    </span>
                  </Col>
                </Row>
                <Row>
                  <Col span={8}>
                    Facility Type <span style={{ color: "red" }}>*</span>:{" "}
                  </Col>
                  <Col span={16}>
                    <Select
                      disabled={loadingCreateFacility}
                      value={facilityRegistrationData?.facility_type}
                      onChange={(e) =>
                        setFacilityRegistrationData((d) => ({
                          ...d,
                          facility_type: e,
                        }))
                      }
                      options={facilityType}
                      style={{ width: "100%" }}
                      placeholder="Select Facility Type e.g. Hospital, Dental, Laboratory"
                    />
                  </Col>
                </Row>
                <Row>
                  <Col span={8}>
                    Facility Plans <span style={{ color: "red" }}>*</span>:
                  </Col>
                  <Col span={16}>
                    <Select
                      disabled={loadingCreateFacility}
                      value={facilityRegistrationData?.facility_plan_type}
                      onChange={(e) =>
                        setFacilityRegistrationData((d) => ({
                          ...d,
                          facility_plan_type: e,
                        }))
                      }
                      options={[
                        ...packages,
                        { label: "All Plans", value: "all plans" },
                      ]}
                      style={{ width: "100%" }}
                      placeholder="On what plan is the facility onboarded?"
                    />
                  </Col>
                </Row>
                <Row>
                  <Col span={8}>
                    Facility State <span style={{ color: "red" }}>*</span>:
                  </Col>
                  <Col span={16}>
                    <Select
                      disabled={loadingCreateFacility}
                      value={facilityRegistrationData?.facility_state}
                      onChange={(e) =>
                        setFacilityRegistrationData((d) => ({
                          ...d,
                          facility_state: e,
                        }))
                      }
                      options={states}
                      style={{ width: "100%" }}
                      placeholder="What State is the facility located?"
                    />
                  </Col>
                </Row>
                <Row>
                  <Col span={8}>
                    Facility City <span style={{ color: "red" }}>*</span>:
                  </Col>
                  <Col span={16}>
                    <Input
                      disabled={loadingCreateFacility}
                      value={facilityRegistrationData?.facility_city}
                      onChange={(e) =>
                        setFacilityRegistrationData((d) => ({
                          ...d,
                          facility_city: e.target.value,
                        }))
                      }
                      placeholder="What city, town or LGA is the facility located?"
                    />
                  </Col>
                </Row>
                <Row>
                  <Col span={8}>
                    Facility Address <span style={{ color: "red" }}>*</span>:
                  </Col>
                  <Col span={16}>
                    <TextArea
                      disabled={loadingCreateFacility}
                      value={facilityRegistrationData?.facility_address}
                      onChange={(e) =>
                        setFacilityRegistrationData((d) => ({
                          ...d,
                          facility_address: e.target.value,
                        }))
                      }
                      rows={3}
                      placeholder="How can we get to this facility"
                    />
                  </Col>
                </Row>
                <Row>
                  <Col span={8}>Constact Person:</Col>
                  <Col span={16}>
                    <Input
                      disabled={loadingCreateFacility}
                      value={facilityRegistrationData?.facility_designation}
                      onChange={(e) =>
                        setFacilityRegistrationData((d) => ({
                          ...d,
                          facility_designation: e.target.value,
                        }))
                      }
                      rows={3}
                      placeholder="Who is the contact person for this facility?"
                    />
                  </Col>
                </Row>
                <Row>
                  <Col span={8}>
                    Facility Email <span style={{ color: "red" }}>*</span>:
                  </Col>
                  <Col span={16}>
                    <Input
                      disabled={loadingCreateFacility}
                      value={facilityRegistrationData?.facility_email}
                      onChange={(e) =>
                        setFacilityRegistrationData((d) => ({
                          ...d,
                          facility_email: e.target.value,
                        }))
                      }
                      placeholder="What is the email of this facility?"
                    />
                  </Col>
                </Row>
                <Row>
                  <Col span={8}>
                    Phone Number <span style={{ color: "red" }}>*</span>:
                  </Col>
                  <Col span={16}>
                    <Input
                      disabled={loadingCreateFacility}
                      value={facilityRegistrationData?.facility_phone}
                      onChange={(e) =>
                        setFacilityRegistrationData((d) => ({
                          ...d,
                          facility_phone: e.target.value,
                        }))
                      }
                      placeholder="What is the phone number of this facility?"
                    />
                  </Col>
                </Row>
                <Row>
                  <Col span={8}>Onboarded By:</Col>
                  <Col span={16}>
                    <Input
                      // disabled={loadingCreateFacility}
                      disabled
                      value={facilityRegistrationData?.facility_staff_id}
                      onChange={(e) =>
                        setFacilityRegistrationData((d) => ({
                          ...d,
                          facility_staff_id: e.target.value,
                        }))
                      }
                      placeholder="Who onboarded this facility?"
                    />
                  </Col>
                </Row>
              </Col>
            </Row>
          </Col>
        </Row>
      </Modal>
    </>
  );
};

const SearchDataBio = ({ type, data, action }) => {
  const [show, setShow] = useState(false);

  const launch = (pol, type) => {
    setShow(true);
  };

  return (
    <>
      {type === "facility" ? (
        <>
          <Row
            style={{
              padding: "10px",
              borderBottom: "1px solid black",
              borderTop: "1px solid black",
              margin: "10px 0",
              cursor: "pointer",
            }}
            onClick={() => launch(data, "facility")}
          >
            <Col span={12}>
              <Row>
                <Col span={6}>Name: </Col>
                <Col span={18}>{data?.facility_name}</Col>
              </Row>
              <Row>
                <Col span={6}>Address: </Col>
                <Col span={18}>{data?.facility_address}</Col>
              </Row>
              <Row>
                <Col span={6}>Plan Category: </Col>
                <Col span={18}>{data?.facility_plan_type}</Col>
              </Row>
            </Col>
            <Col span={8}>
              <Row>
                <Col span={8}>Designation: </Col>
                <Col span={16}>{data?.facility_designation}</Col>
              </Row>
              <Row>
                <Col span={8}>Email: </Col>
                <Col span={16}>{data?.facility_email}</Col>
              </Row>
              <Row>
                <Col span={8}>Phone: </Col>
                <Col span={16}>{data?.facility_phone}</Col>
              </Row>
            </Col>
            <Col span={4}>
              <Row>
                <Col span={8}>State: </Col>
                <Col span={16}>{data?.facility_state}</Col>
              </Row>
              <Row>
                <Col span={8}>City: </Col>
                <Col span={16}>{data?.facility_city}</Col>
              </Row>
              {/* <Row>
                <Col span={8}>Phone: </Col>
                <Col span={16}>{data?.facility_phone}</Col>
              </Row> */}
            </Col>
          </Row>
        </>
      ) : (
        <>
          {type === "clients" ? (
            <Row
              style={{
                padding: "10px",
                borderBottom: "1px solid black",
                borderTop: "1px solid black",
                margin: "10px 0",
                cursor: "pointer",
              }}
              onClick={() => launch(data, "clients")}
            >
              <Col span={8}>
                <Row>
                  <Col span={8}>Name: </Col>
                  <Col span={16}>{data?.name}</Col>
                </Row>
              </Col>
              <Col span={10}>
                <Row>
                  <Col span={10}>Insurance Package: </Col>
                  <Col span={14}>{data?.insurance_package}</Col>
                </Row>
              </Col>
              <Col span={6}>
                <Row>
                  <Col span={12}>Premium Type: </Col>
                  <Col span={12}>{data?.premium_type?.toUpperCase()}</Col>
                </Row>
              </Col>
              <Col span={8}>
                <Row>
                  <Col span={8}>Enrolee Size: </Col>
                  <Col span={16}>{data?.enrolee_size}</Col>
                </Row>
              </Col>
              <Col span={10}>
                <Row>
                  <Col span={10}>Email: </Col>
                  <Col span={14}>{data?.email}</Col>
                </Row>
              </Col>
              <Col span={6}>
                <Row>
                  <Col span={12}>Phone: </Col>
                  <Col span={12}>{data?.phone}</Col>
                </Row>
              </Col>
              <Col span={8}>
                <Row>
                  <Col span={8}>Policy Number: </Col>
                  <Col span={16}>{data?.policy_no}</Col>
                </Row>
              </Col>
              <Col span={10}>
                <Row>
                  <Col span={10}>Reg. Date: </Col>
                  <Col span={14}>{data?.date}</Col>
                </Row>
              </Col>
              <Col span={6}>
                <Row>
                  <Col span={12}>State / City: </Col>
                  <Col span={12}>
                    {data?.state?.toUpperCase()} / {data?.city?.toUpperCase()}
                  </Col>
                </Row>
              </Col>
              {/* <Col span={2}>
                  <Dropdown.Button overlay={menuProps}>Action</Dropdown.Button>
                </Col> */}
            </Row>
          ) : (
            <Row
              style={{
                padding: "10px",
                borderBottom: "1px solid black",
                borderTop: "1px solid black",
                margin: "10px 0",
                cursor: "pointer",
              }}
              onClick={() => launch(data, "enrollee")}
            >
              <Col span={2}>
                <Row>
                  <Col span={24}>
                    <Avatar
                      size={64}
                      icon={
                        <img
                          src={`${uri_img}${data?.pic_name}`}
                          alt={data?.first_name}
                        />
                      }
                    />
                  </Col>
                </Row>
              </Col>
              <Col span={22}>
                <Row>
                  <Col span={12}>
                    <Row>
                      <Col span={8}>Full Name: </Col>
                      <Col span={16}>
                        {data?.first_name} {data?.middle_name}{" "}
                        {data?.surname?.toUpperCase()}
                      </Col>
                    </Row>
                  </Col>
                  <Col span={6}>
                    <Row>
                      <Col span={8}>Role: </Col>
                      <Col span={16}>{data?.role?.toUpperCase()}</Col>
                    </Row>
                  </Col>
                  <Col span={6}>
                    <Row>
                      <Col span={8}>State / LGA: </Col>
                      <Col span={16}>
                        {data?.state?.toUpperCase()} /{" "}
                        {data?.lga?.toUpperCase()}
                      </Col>
                    </Row>
                  </Col>
                  <Col span={6}>
                    <Row>
                      <Col span={10}>Policy No.: </Col>
                      <Col span={14}>{data?.policy_no}</Col>
                    </Row>
                    <Row>
                      <Col span={10}>Insurance No.: </Col>
                      <Col span={14}>{data?.insurance_no}</Col>
                    </Row>
                  </Col>
                  <Col span={18}>
                    <Row>
                      <Col span={8}>Primary Heath Facility: </Col>
                      <Col span={16}>{data?.primary_health_facility}</Col>
                    </Row>
                    <Row>
                      <Col span={8}>Altenative Health Facility: </Col>
                      <Col span={16}>{data?.secondary_health_facility}</Col>
                    </Row>
                  </Col>
                  <Col span={8}>
                    <Row>
                      <Col span={8}>Age: </Col>
                      <Col span={16}>
                        {data?.date_of_birth
                          ? calculateAgeAlt(data?.date_of_birth)
                          : "Invalid Age"}
                      </Col>
                    </Row>
                  </Col>
                </Row>
              </Col>
            </Row>
          )}
        </>
      )}
      <ModalComponentClent
        type={type}
        data={data}
        display={show}
        action={() => {setShow(false)}}
        command={action}
      />
    </>
  );
};

const ModalComponentClent = ({ type, data, display, action, command }) => {
  const { user } = useAuth();
  const [n_data, setNdata] = useState(data);
  const [edit, setEdit] = useState(false);
  const [update, setUpdate] = useState(false);
  const [loadingUpdate, setLoadingUpdate] = useState(false);
  const [loadingFlag, setLoadingFlag] = useState(false);
  const [loadingDisable, setLoadingDisable] = useState(false);
  const [loadingActivate, setLoadingActivate] = useState(false);
  const [loadingRevoke, ] = useState(false);

  const [showEnrolleeAddition, setShowEnrolleeAddition] = useState(false);

  const [validatingSocial, setValidatingSocial] = useState(false);
  const [socialDetail, setSocialDetail] = useState(false);

  const [updateCategory, setUpdateCategory] = useState(false);
  const [updateCategoryValidating, setUpdateCategoryValidating] =
    useState(false);
  const [categoryUpdateData, setCategoryUpdateData] = useState({});
  const [categoryVerified, setCategoryVerified] = useState(false);
  const [UpdateSocialDetail, setUpdateSocialDetail] = useState("");

  const [stateHealthCare, setStateHealtCare] = useState([]);
  const [loading, setLoading] = useState(false);
  const [providerError, setProviderError] = useState(false);

  const [showImgUpload, setShowImgUpload] = useState(false);

  const [showSetFacilityPassword, setShowSetFacilityPassword] = useState(false);

  const { confirm } = Modal;

  useEffect(() => {
    const vn = Object.entries(n_data).filter((v, i) => v[1] !== data[v[0]]);
    if (vn.length) setUpdate(true);
  }, [n_data]); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    if (display && !(type === "clients" || type === "facility")) {
      fetchProviders();
      handleFocusOut();
    }
  }, [display]); // eslint-disable-line react-hooks/exhaustive-deps

  const updateData = async (e, data = {}) => {
    if (providerError) {
      notification.error({
        message: "There is an error preventing this action",
      });
      return;
    }

    const targ = e;

    if (Number(targ) === 1) {
      setLoadingUpdate(true);
    } else if (Number(targ) === 2) {
      setLoadingActivate(true);
    } else if (Number(targ) === 3) {
      setLoadingDisable(true);
    } else if (Number(targ) === 4) {
      setLoadingFlag(true);
    }

    setEdit(false);

    lib
      .updateUserData({ ...n_data, ...data })
      .then((res) => res.data)
      .then((data) => {
        if (!data?.error) {
          if (Number(targ) === 4) {
            notification.success({
              message: data?.message,
              placement: "topLeft",
            });
            return;
          }
          command()
          // window.location.reload();
        } else {
          notification.error({
            message: data?.message,
            placement: "topLeft",
          });
        }
      })
      .catch((err) => {
        setEdit(true);
      })
      .finally(() => {
        if (Number(targ) === 1) {
          setLoadingUpdate(false);
        } else if (Number(targ) === 2) {
          setLoadingActivate(false);
        } else if (Number(targ) === 3) {
          setLoadingDisable(false);
        } else if (Number(targ) === 4) {
          setLoadingFlag(false);
        }

        setUpdate(false);
        action();
      });
  };

  const updateDataFacility = async (e, thisData = {}) => {
    const targ = e;
    if (Number(targ) === 1) {
      setLoadingUpdate(true);
    } else if (Number(targ) === 2) {
      setLoadingActivate(true);
    } else if (Number(targ) === 3) {
      setLoadingDisable(true);
    } else if (Number(targ) === 4) {
      setLoadingFlag(true);
    }

    setEdit(false);

    lib
      .updateProvider({ ...n_data, ...thisData })
      .then((res) => res)
      .then((data) => {
        if (!data?.error) {
          if (Number(targ) === 4) {
            return;
          }

          notification.success({
            message: data?.message,
            placement: "topLeft",
          });

          setTimeout(() => window.location.reload(), 2000);
        } else {
          notification.error({
            message: data?.message,
            placement: "topLeft",
          });
        }
      })
      .catch((err) => {
        setEdit(true);
      })
      .finally(() => {
        if (Number(targ) === 1) {
          setLoadingUpdate(false);
        } else if (Number(targ) === 2) {
          setLoadingActivate(false);
        } else if (Number(targ) === 3) {
          setLoadingDisable(false);
        } else if (Number(targ) === 4) {
          setLoadingFlag(false);
        }

        setUpdate(false);
        action();
      });
  };

  const handleFocusOut = async (e) => {
    if(data?.category !== "corporate") return;

    setValidatingSocial(true);
    let obj = {
      social_no: data?.social_no,
      mode: data?.category,
    };

    let reqData = await lib.validatSocial(obj);

    if (reqData?.error === 0) {
      setSocialDetail(reqData?.data);
    }

    setValidatingSocial(false);
  };

  const configFlag = {
    title: (
      <>Flag - {type === "clients" ? data?.policy_no : data?.insurance_no}</>
    ),
    onOk: () => {
      updateData(4);
    },
    okText: "Raise Flag",
    width: 700,
    centered: true,
    maskClosable: true,
    content: (
      <>
        <Row gutter={[16, 16]}>
          <Col span={24}>Flag Memo: </Col>
          <Col span={24}>
            <TextArea
              onChange={(e) =>
                setNdata((d) => ({ ...d, flag_reason: e.target.value }))
              }
              style={{ width: "100%" }}
              rows={4}
            />
          </Col>
        </Row>
      </>
    ),
  };

  const configUnFlag = {
    title: (
      <>Unflag - {type === "clients" ? data?.policy_no : data?.insurance_no}</>
    ),
    onOk() {
      updateData(4);
    },
    centered: true,
    maskClosable: true,
    okText: "Remove Flag",
    content: (
      <>
        <Row>
          <Col span={24}>Flag Memo: </Col>
          <Col span={24}>
            <TextArea
              onChange={(e) => setNdata((d) => ({ ...d, flag_reason: e }))}
              style={{ width: "100%" }}
              rows={4}
            />
          </Col>
        </Row>
      </>
    ),
  };

  const updateUseCategory = () => {
    if (
      categoryUpdateData?.category === "self pay" &&
      categoryUpdateData?.social_no
    )
      setCategoryUpdateData((d) => ({ ...d, social_no: "" }));

    lib
      .updateUserData({ ...n_data, ...categoryUpdateData })
      .then((res) => res.data)
      .then((data) => {
        if (!data?.error) {
          notification.success({
            message: data?.message,
            placement: "topLeft",
          });
          // window.location.reload();
        } else {
          notification.error({
            message: data?.message,
            placement: "topLeft",
          });
        }
      })
      .catch((err) => {
        // setEdit(true);
      })
      .finally(() => {});
  };

  const verifyCode = async (e) => {
    setUpdateCategoryValidating(true);
    setCategoryVerified(false);
    let obj = {
      social_no: categoryUpdateData?.social_no || data?.social_no,
      mode: categoryUpdateData?.category || data?.category,
    };

    let reqData = await lib.validatSocial(obj);
    console.log(reqData)
    if (Number(reqData?.data?.error) === 0) {
      notification.success({
        message: reqData?.data?.message,
        placement: "topLeft",
      });
      setUpdateSocialDetail(reqData?.data);
      setCategoryVerified(true);
    } else {
      notification.error({
        message: reqData?.data?.message,
        placement: "topLeft",
      });
    }

    setUpdateCategoryValidating(false);
  };

  const fetchProviders = async (e = null) => {
    let obj = {
      state: e || n_data?.state,
      plan_type: n_data?.insurance_package,
    };

    setLoading(true);
    let reqData = await lib.getProviders(obj);

    if (parseInt(reqData?.data?.error) === 1) {
      setProviderError(true);
      setLoading(false);
      notification.error({
        message: reqData?.data?.message,
        duration: 3,
        placement: "topLeft",
      });
    }

    console.log(reqData?.data?.data)

    if (Number(reqData?.data?.error) === 0) {
      setStateHealtCare(NullSafe(reqData?.data?.data));
      setLoading(false);
    }
  };

  return (
    <>
      {type === "facility" ? (
        <Modal
          title={
            <>
              Facility Detail |{" "}
              <Button type="primary" danger={!data?.facility_status}>
                {data?.facility_status ? "Active" : "Inactive"}
              </Button>
            </>
          }
          centered
          open={display}
          onOk={action}
          onCancel={action}
          footer={
            <>
              {user &&
              [1, 2].includes(Number(user.role["staffRoleId"])) ? (
                <>
                  <Button
                    disabled={
                      !update ||
                      loadingUpdate ||
                      loadingFlag ||
                      loadingDisable ||
                      loadingActivate
                    }
                    onClick={() => updateDataFacility(1)}
                  >
                    {loadingUpdate ? <LoadingOutlined /> : <>Update</>}
                  </Button>
                  {data?.facility_status ? (
                    <Button
                      type="primary"
                      disabled={
                        loadingUpdate ||
                        loadingFlag ||
                        loadingDisable ||
                        loadingActivate
                      }
                      style={{
                        backgroundColor: "grey",
                        border: "1px solid red",
                      }}
                      onClick={() =>
                        updateDataFacility(3, { facility_status: 0 })
                      }
                    >
                      {loadingDisable ? <LoadingOutlined /> : <>Disable</>}
                    </Button>
                  ) : (
                    <Button
                      type="primary"
                      disabled={
                        loadingUpdate ||
                        loadingFlag ||
                        loadingDisable ||
                        loadingActivate
                      }
                      onClick={() =>
                        updateDataFacility(2, { facility_status: 1 })
                      }
                    >
                      {loadingActivate ? <LoadingOutlined /> : <>Activate</>}
                    </Button>
                  )}
                </>
              ) : null}
              {user && [1].includes(Number(user.role["staffRoleId"])) ? 
                <>
                  <Button
                    disabled={
                      loadingUpdate ||
                      loadingFlag ||
                      loadingDisable ||
                      loadingActivate
                    }
                    onClick={() => setShowSetFacilityPassword(true)}
                  >
                    {loadingUpdate ? <LoadingOutlined /> : <>Set Password</>}
                  </Button>
                </> : null
              }
              <Button
                type="dashed"
                disabled={
                  loadingUpdate ||
                  loadingFlag ||
                  loadingDisable ||
                  loadingActivate
                }
                danger
                onClick={action}
              >
                Close
              </Button>
            </>
          }
          width={800}
          destroyOnClose
        >
          <Row>
            <Col span={24}>
              <Row gutter={[16, 8]}>
                <Col span={24}>
                  <Row>
                    <Col span={8}>Facility Name:</Col>
                    <Col span={16}>
                      <TextArea
                        disabled={!edit}
                        value={n_data?.facility_name}
                        onChange={(e) =>
                          setNdata((d) => ({
                            ...d,
                            facility_name: e.target.value,
                          }))
                        }
                      />
                    </Col>
                  </Row>
                  <Row>
                    <Col span={8}>Facility Type: </Col>
                    <Col span={16}>
                      <Select
                        disabled={!edit}
                        value={n_data?.facility_type}
                        onChange={(e) =>
                          setNdata((d) => ({ ...d, facility_type: e }))
                        }
                        options={facilityType}
                        style={{ width: "100%" }}
                      />
                    </Col>
                  </Row>
                  <Row>
                    <Col span={8}>Facility Plans:</Col>
                    <Col span={16}>
                      <Select
                        disabled={!edit}
                        value={n_data?.facility_plan_type}
                        onChange={(e) =>
                          setNdata((d) => ({ ...d, facility_plan_type: e }))
                        }
                        options={[
                          ...packages,
                          { label: "All Plans", value: "all plans" },
                        ]}
                        style={{ width: "100%" }}
                      />
                    </Col>
                  </Row>
                  <Row>
                    <Col span={8}>Facility State:</Col>
                    <Col span={16}>
                      <Select
                        disabled={!edit}
                        value={n_data?.facility_state}
                        onChange={(e) =>
                          setNdata((d) => ({
                            ...d,
                            facility_state: e.target.value,
                          }))
                        }
                        options={states}
                        style={{ width: "100%" }}
                      />
                    </Col>
                  </Row>
                  <Row>
                    <Col span={8}>Facility City:</Col>
                    <Col span={16}>
                      <Input
                        disabled={!edit}
                        value={n_data?.facility_city}
                        onChange={(e) =>
                          setNdata((d) => ({
                            ...d,
                            facility_city: e.target.value,
                          }))
                        }
                      />
                    </Col>
                  </Row>
                  <Row>
                    <Col span={8}>Facility Phone:</Col>
                    <Col span={16}>
                      <Input
                        disabled={!edit}
                        value={n_data?.facility_phone}
                        onChange={(e) =>
                          setNdata((d) => ({
                            ...d,
                            facility_phone: e.target.value,
                          }))
                        }
                      />
                    </Col>
                  </Row>
                  <Row>
                    <Col span={8}>Facility Email:</Col>
                    <Col span={16}>
                      <InputCopy
                        disabled={!edit}
                        value={n_data?.facility_email}
                        onChange={(e) =>
                          setNdata((d) => ({
                            ...d,
                            facility_email: e.target.value,
                          }))
                        }
                      />
                    </Col>
                  </Row>
                  <Row>
                    <Col span={8}>Facility Address:</Col>
                    <Col span={16}>
                      <TextArea
                        disabled={!edit}
                        value={n_data?.facility_address}
                        onChange={(e) =>
                          setNdata((d) => ({
                            ...d,
                            facility_address: e.target.value,
                          }))
                        }
                        rows={3}
                      />
                    </Col>
                  </Row>
                  <Row>
                    <Col span={8}>Constact Person:</Col>
                    <Col span={16}>
                      <Input
                        disabled={!edit}
                        value={n_data?.facility_designation}
                        onChange={(e) =>
                          setNdata((d) => ({
                            ...d,
                            facility_designation: e.target.value,
                          }))
                        }
                        rows={3}
                      />
                    </Col>
                  </Row>
                  <Row>
                    <Col span={8}>Regitration Onboarded:</Col>
                    <Col span={16}>
                      <Input
                        disabled
                        value={n_data?.facility_date}
                        onChange={(e) =>
                          setNdata((d) => ({
                            ...d,
                            facility_date: e.target.value,
                          }))
                        }
                      />
                    </Col>
                  </Row>
                  <Row>
                    <Col span={8}>Onboarded By:</Col>
                    <Col span={16}>
                      <Input
                        disabled
                        value={n_data?.facility_staff_id}
                        onChange={(e) =>
                          setNdata((d) => ({
                            ...d,
                            facility_staff_id: e.target.value,
                          }))
                        }
                      />
                    </Col>
                  </Row>
                </Col>
                <Col span={24}>
                  <Row justify="end" gutter={[16, 16]}>
                    <Col
                      style={{ textAlign: "right", alignContent: "flex-end" }}
                    >
                      {user &&
                      [1, 2, 4, 5, 6].includes(
                        Number(user.role["staffRoleId"])
                      ) ? (
                        <>
                          {edit ? (
                            <CheckOutlined
                              onClick={() => setEdit(false)}
                              style={{ fontSize: 30, color: "blue" }}
                              title="Done"
                            />
                          ) : (
                            <EditOutlined
                              onClick={() => setEdit(true)}
                              style={{ fontSize: 30, color: "blue" }}
                            />
                          )}
                        </>
                      ) : null}
                    </Col>
                  </Row>
                </Col>
              </Row>
            </Col>
          </Row>
        </Modal>
      ) : (
        <Modal
          title={
            <>
              User Data -{" "}
              {type === "clients" ? data?.policy_no : data?.insurance_no} |{" "}
              <Button type="primary" danger={!data?.status}>
                {data?.status ? "Active" : "Inactive"}
              </Button>
            </>
          }
          centered
          open={display}
          onOk={action}
          onCancel={action}
          footer={
            <>
              {user && [1, 2].includes(Number(user.role["staffRoleId"])) ? (
                <>
                  {data?.status ? (
                    <Button
                      disabled={
                        !update ||
                        loadingUpdate ||
                        loadingFlag ||
                        loadingDisable ||
                        loadingActivate
                      }
                      onClick={() => updateData(1)}
                    >
                      {loadingUpdate ? <LoadingOutlined /> : <>Update</>}
                    </Button>
                  ) : null}

                  {data?.status ? (
                    <Button
                      type="primary"
                      disabled={
                        loadingUpdate ||
                        loadingFlag ||
                        loadingDisable ||
                        loadingActivate
                      }
                      style={{
                        backgroundColor: "grey",
                        border: "1px solid red",
                      }}
                      onClick={() => updateData(3, { status: 0 })}
                    >
                      {loadingDisable ? <LoadingOutlined /> : <>Disable</>}
                    </Button>
                  ) : data?.updated && !data?.update_required ? (
                    <>
                      <Button
                        type="primary"
                        disabled={
                          loadingUpdate ||
                          loadingFlag ||
                          loadingDisable ||
                          loadingActivate
                        }
                        onClick={() => updateData(2, { status: 1 })}
                      >
                        {loadingActivate ? <LoadingOutlined /> : <>Activate</>}
                      </Button>
                      <Button
                        type="primary"
                        danger
                        disabled={
                          loadingUpdate ||
                          loadingFlag ||
                          loadingDisable ||
                          loadingActivate ||
                          loadingRevoke
                        }
                        onClick={() =>
                          updateData(5, {
                            status: 0,
                            update_required: 1,
                            updated: 0,
                          })
                        }
                      >
                        {loadingRevoke ? <LoadingOutlined /> : <>Revoke</>}
                      </Button>
                    </>
                  ) : null}

                  {data?.status ? (
                    data?.flag ? (
                      <Button
                        type="primary"
                        disabled={
                          loadingUpdate ||
                          loadingFlag ||
                          loadingDisable ||
                          loadingActivate
                        }
                        danger
                        onClick={() => {
                          setNdata((d) => ({ ...d, flagged: 0 }));
                          confirm(configUnFlag);
                        }}
                      >
                        {loadingFlag ? <LoadingOutlined /> : <>Unflag</>}
                      </Button>
                    ) : (
                      <Button
                        type="primary"
                        disabled={
                          loadingUpdate ||
                          loadingFlag ||
                          loadingDisable ||
                          loadingActivate
                        }
                        danger
                        onClick={() => {
                          setNdata((d) => ({ ...d, flagged: 1 }));
                          confirm(configFlag);
                        }}
                      >
                        {loadingFlag ? <LoadingOutlined /> : <>Flag</>}
                      </Button>
                    )
                  ) : null}

                  {type === "client_biodata" &&
                  parseInt(data?.status) &&
                  !parseInt(data?.update_required) &&
                  parseInt(data?.updated) ? (
                    <CareCard data={data} key={data?.id} />
                  ) : null}
                </>
              ) : null}
              <Button
                type="dashed"
                disabled={
                  loadingUpdate ||
                  loadingFlag ||
                  loadingDisable ||
                  loadingActivate
                }
                danger
                onClick={action}
              >
                Close
              </Button>
            </>
          }
          width={800}
          destroyOnClose
        >
          {type !== "clients" ? (
            <Row>
              <Col span={24}>
                <Row gutter={[16, 8]}>
                  <Col span={19}>
                    <Row>
                      <Col span={10}>Title:</Col>
                      <Col span={14}>
                        <Select
                          disabled={!edit}
                          value={n_data?.title}
                          onChange={(e) =>
                            setNdata((d) => ({ ...d, title: e }))
                          }
                          options={title}
                          style={{ width: "100%" }}
                        />
                      </Col>
                    </Row>
                    <Row>
                      <Col span={10}>First Name:</Col>
                      <Col span={14}>
                        <Input
                          disabled={!edit}
                          value={n_data?.first_name}
                          onChange={(e) =>
                            setNdata((d) => ({
                              ...d,
                              first_name: e.target.value,
                            }))
                          }
                        />
                      </Col>
                    </Row>
                    <Row>
                      <Col span={10}>Middle Name:</Col>
                      <Col span={14}>
                        <Input
                          disabled={!edit}
                          value={n_data?.middle_name}
                          onChange={(e) =>
                            setNdata((d) => ({
                              ...d,
                              middle_name: e.target.value,
                            }))
                          }
                        />
                      </Col>
                    </Row>
                    <Row>
                      <Col span={10}>Surname:</Col>
                      <Col span={14}>
                        <Input
                          disabled={!edit}
                          value={n_data?.surname}
                          onChange={(e) =>
                            setNdata((d) => ({ ...d, surname: e.target.value }))
                          }
                        />
                      </Col>
                    </Row>
                  </Col>
                  <Col span={5}>
                    <Avatar
                      size={140}
                      shape="square"
                      icon={
                        <img
                          src={`${uri_img}${data?.pic_name}`}
                          alt={data?.first_name?.slice(0, 2)?.toUpperCase()}
                          onClick={e => setShowImgUpload(true)}
                        />
                      }
                      style={{cursor: 'pointer', border: `3px solid ${Number(data?.status)? 'skyblue' : 'c0c0c0' }`, boxShadow: `2px 1px 1px ${Number(data?.status)? 'skyblue' : 'c0c0c0' }`}}
                    />
                    { (user && [2].includes(Number(user.role["staffRoleId"])) && Number(data?.status) && showImgUpload) ? <UploadImage data={n_data} style={{ color: "#ffeeff" }} key={n_data?.id} onClose={() => setShowImgUpload(false)} show={showImgUpload} /> : null }
                  </Col>
                  <Col span={24}>
                    <Row>
                      <Col span={8}>Gender:</Col>
                      <Col span={16}>
                        <Select
                          disabled
                          value={n_data?.gender}
                          onChange={(e) =>
                            setNdata((d) => ({ ...d, gender: e }))
                          }
                          options={gender}
                          style={{ width: "100%" }}
                        />
                      </Col>
                    </Row>
                    <Row>
                      <Col span={8}>Age: </Col>
                      <Col span={16}>
                        <Input
                          disabled
                          value={
                            data?.date_of_birth
                              ? calculateAgeAlt(data?.date_of_birth)
                              : "Invalid Age"
                          }
                        />
                      </Col>
                    </Row>
                    <Row>
                      <Col span={8}>Phone:</Col>
                      <Col span={16}>
                        <Input
                          disabled={!edit}
                          value={n_data?.phone}
                          onChange={(e) =>
                            setNdata((d) => ({ ...d, phone: e.target.value }))
                          }
                        />
                      </Col>
                    </Row>
                    <Row>
                      <Col span={8}>Role:</Col>
                      <Col span={16}>
                        <Select
                          disabled={!edit}
                          value={n_data?.role}
                          onChange={(e) => setNdata((d) => ({ ...d, role: e }))}
                          options={role}
                          style={{ width: "100%" }}
                        />
                      </Col>
                    </Row>
                    <Row>
                      <Col span={8}>Address:</Col>
                      <Col span={16}>
                        <TextArea
                          disabled={!edit}
                          value={n_data?.contact_address}
                          onChange={(e) =>
                            setNdata((d) => ({ ...d, address: e.target.value }))
                          }
                          rows={3}
                        />
                      </Col>
                    </Row>
                    <Row>
                      <Col span={8}>State:</Col>
                      <Col span={16}>
                        <Select
                          disabled={!edit}
                          value={n_data?.state}
                          onChange={(e) => {
                            setNdata((d) => ({ ...d, state: e }));
                            fetchProviders(e);
                          }}
                          options={states}
                          style={{ width: "100%" }}
                        />
                      </Col>
                    </Row>
                    <Row>
                      <Col span={8}>City:</Col>
                      <Col span={16}>
                        <Input
                          disabled={!edit}
                          value={n_data?.lga}
                          onChange={(e) =>
                            setNdata((d) => ({ ...d, lga: e.target.value }))
                          }
                          options={states}
                        />
                      </Col>
                    </Row>
                    <Row>
                      <Col span={8} style={{ color: providerError && "red" }}>
                        Primary Health Facility:
                      </Col>
                      <Col span={16}>
                        <Select
                          disabled={!edit}
                          style={{
                            width: loading ? "95%" : "100%",
                            color: providerError && "red",
                          }}
                          onChange={(e) =>
                            setNdata((d) => ({
                              ...d,
                              primary_health_facility: e,
                            }))
                          }
                          value={n_data?.primary_health_facility}
                        >
                          {stateHealthCare?.map((item) => (
                            <>
                              <Option
                                value={item.facility_name}
                                title={
                                  item.facility_name +
                                  " - " +
                                  item.facility_plan_type.toUpperCase()
                                }
                                style={{ borderBottom: "1px solid #91caff" }}
                              >
                                <span>
                                  {item.facility_name}{" "}
                                  <Divider
                                    type="vertical"
                                    style={{
                                      margin: 5,
                                      borderColor: "#001d66",
                                    }}
                                  />{" "}
                                  {item.facility_plan_type.toUpperCase()}
                                </span>
                                <Divider style={{ margin: 0 }} />
                                <span>Address: {item.facility_address}</span>
                                <Divider style={{ margin: 0 }} />
                                <span>
                                  State: {item.facility_state}{" "}
                                  <Divider
                                    type="vertical"
                                    style={{
                                      margin: 5,
                                      borderColor: "#001d66",
                                    }}
                                  />{" "}
                                  City: {item.facility_city.toUpperCase()}
                                </span>
                              </Option>
                            </>
                          ))}
                        </Select>
                        {loading && <Spin size="small" />}
                      </Col>
                    </Row>
                    <Row>
                      <Col span={8} style={{ color: providerError && "red" }}>
                        Alternative Health Facility:
                      </Col>
                      <Col span={16}>
                        <Select
                          disabled={!edit}
                          style={{
                            width: loading ? "95%" : "100%",
                            color: providerError && "red",
                          }}
                          onChange={(e) =>
                            setNdata((d) => ({
                              ...d,
                              secondary_health_facility: e,
                            }))
                          }
                          value={n_data?.secondary_health_facility}
                        >
                          {stateHealthCare?.map((item) => (
                            <>
                              <Option
                                value={item.facility_name}
                                title={
                                  item.facility_name +
                                  " - " +
                                  item.facility_plan_type.toUpperCase()
                                }
                                style={{ borderBottom: "1px solid #91caff" }}
                              >
                                <span>
                                  {item.facility_name}{" "}
                                  <Divider
                                    type="vertical"
                                    style={{
                                      margin: 5,
                                      borderColor: "#001d66",
                                    }}
                                  />{" "}
                                  {item.facility_plan_type.toUpperCase()}
                                </span>
                                <Divider style={{ margin: 0 }} />
                                <span>Address: {item.facility_address}</span>
                                <Divider style={{ margin: 0 }} />
                                <span>
                                  State: {item.facility_state}{" "}
                                  <Divider
                                    type="vertical"
                                    style={{
                                      margin: 5,
                                      borderColor: "#001d66",
                                    }}
                                  />{" "}
                                  City: {item.facility_city.toUpperCase()}
                                </span>
                              </Option>
                            </>
                          ))}
                        </Select>
                        {loading && <Spin size="small" />}
                      </Col>
                    </Row>
                    <Row>
                      <Col span={8}>Existing Medical Condition:</Col>
                      <Col span={16}>
                        <TextArea
                          disabled
                          value={n_data?.existing_medical_condition}
                          onChange={(e) =>
                            setNdata((d) => ({
                              ...d,
                              existing_medical_condition: e.target.value,
                            }))
                          }
                          rows={3}
                        />
                      </Col>
                    </Row>
                    <Row>
                      <Col span={8}>Pre-existing Medical Condition:</Col>
                      <Col span={16}>
                        <TextArea
                          disabled
                          value={n_data?.previous_medical_condition}
                          onChange={(e) =>
                            setNdata((d) => ({
                              ...d,
                              previous_medical_condition: e.target.value,
                            }))
                          }
                          rows={3}
                        />
                      </Col>
                    </Row>
                  </Col>
                  <Col span={24}>
                    <Row justify="end" gutter={[16, 16]}>
                      <Col
                        span={8}
                        style={{
                          textAlign: "right",
                          alignContent: "flex-end",
                          display: "flex",
                          gap: "20px",
                          justifyContent: "flex-end",
                        }}
                      >
                        {user &&
                        [2].includes(
                          Number(user.role["staffRoleId"])
                        ) ? (
                          <>
                            {data?.status ? (
                              <>
                                {edit ? (
                                  <CheckOutlined
                                    onClick={() => setEdit(false)}
                                    style={{ fontSize: 30, color: "blue" }}
                                    title="Done"
                                  />
                                ) : (
                                  <EditOutlined
                                    onClick={() => setEdit(true)}
                                    style={{ fontSize: 30, color: "blue" }}
                                  />
                                )}
                              </>
                            ) : null}
                          </>
                        ) : null}
                      </Col>
                    </Row>
                  </Col>
                </Row>
              </Col>
            </Row>
          ) : (
            <Row>
              <Col span={24}>
                <Row gutter={[16, 8]}>
                  <Col span={24}>
                    <Row>
                      <Col span={8}>Name:</Col>
                      <Col span={16}>
                        <Input
                          disabled={!edit}
                          value={n_data?.name}
                          onChange={(e) =>
                            setNdata((d) => ({ ...d, name: e.target.value }))
                          }
                        />
                      </Col>
                    </Row>
                    <Row>
                      <Col span={8}>Insurance Package: </Col>
                      <Col span={16}>
                        <Select
                          disabled={!edit}
                          value={n_data?.insurance_package}
                          onChange={(e) =>
                            setNdata((d) => ({ ...d, insurance_package: e }))
                          }
                          options={packages}
                          style={{ width: "100%" }}
                        />
                      </Col>
                    </Row>
                    <Row>
                      <Col span={8}>Premium Type:</Col>
                      <Col span={16}>
                        <Select
                          disabled={!edit}
                          value={n_data?.premium_type}
                          onChange={(e) =>
                            setNdata((d) => ({ ...d, premium_type: e }))
                          }
                          options={policy_type}
                          style={{ width: "100%" }}
                        />
                      </Col>
                    </Row>
                    <Row>
                      <Col span={8}>Premium Category:</Col>
                      <Col span={12}>
                        <Input disabled value={n_data?.category} />
                      </Col>
                      <Col span={4}>
                        <Button
                          type="dashed"
                          danger
                          onClick={() => setUpdateCategory(true)}
                          style={{ width: "100%" }}
                        >
                          Change
                        </Button>
                      </Col>
                    </Row>
                    {data?.social_no && data?.category === "corporate" ? (
                      <Row>
                        <Col span={8}>Association Code:</Col>
                        <Col span={16}>
                          <Input
                            disabled
                            value={socialDetail?.org_name || n_data?.social_no}
                            onChange={(e) =>
                              setNdata((d) => ({ ...d, phone: e.target.value }))
                            }
                            suffix={
                              validatingSocial && !socialDetail ? (
                                <LoadingOutlined style={{ color: "blue" }} />
                              ) : !validatingSocial && socialDetail ? (
                                <CheckOutlined style={{ color: "green" }} />
                              ) : (
                                <WarningOutlined style={{ color: "gold" }} />
                              )
                            }
                          />
                        </Col>
                      </Row>
                    ) : null}
                    <Row>
                      <Col span={8}>Phone:</Col>
                      <Col span={16}>
                        <Input
                          disabled
                          value={n_data?.phone}
                          onChange={(e) =>
                            setNdata((d) => ({ ...d, phone: e.target.value }))
                          }
                        />
                      </Col>
                    </Row>
                    <Row>
                      <Col span={8}>Policy No.:</Col>
                      <Col span={16}>
                        <Input
                          disabled
                          value={n_data?.policy_no}
                          onChange={(e) =>
                            setNdata((d) => ({
                              ...d,
                              policy_no: e.target.value,
                            }))
                          }
                        />
                      </Col>
                    </Row>
                    <Row>
                      <Col span={8}>Enrolee Size:</Col>
                      <Col span={16}>
                        <Input
                          disabled={!edit}
                          value={n_data?.enrolee_size}
                          onChange={(e) =>
                            setNdata((d) => ({
                              ...d,
                              enrolee_size: e.target.value,
                            }))
                          }
                        />
                      </Col>
                    </Row>
                    <Row>
                      <Col span={8}>Email:</Col>
                      <Col span={16}>
                        <Input
                          disabled
                          value={n_data?.email}
                          onChange={(e) =>
                            setNdata((d) => ({ ...d, email: e.target.value }))
                          }
                          rows={3}
                        />
                      </Col>
                    </Row>
                    <Row>
                      <Col span={8}>State:</Col>
                      <Col span={16}>
                        <Select
                          disabled={!edit}
                          value={n_data?.state}
                          onChange={(e) =>
                            setNdata((d) => ({ ...d, state: e }))
                          }
                          options={states}
                          style={{ width: "100%" }}
                        />
                      </Col>
                    </Row>
                    <Row>
                      <Col span={8}>City:</Col>
                      <Col span={16}>
                        <Input
                          disabled={!edit}
                          value={n_data?.city}
                          onChange={(e) => {
                            setNdata((d) => ({ ...d, city: e.target.value }));
                          }}
                        />
                      </Col>
                    </Row>
                    <Row>
                      <Col span={8}>Regitration. Date:</Col>
                      <Col span={16}>
                        <Input
                          disabled
                          value={n_data?.date}
                          onChange={(e) =>
                            setNdata((d) => ({ ...d, date: e.target.value }))
                          }
                        />
                      </Col>
                    </Row>
                    <Row>
                      <Col span={8}>Expiration Date:</Col>
                      <Col span={16}>
                        <Input
                          disabled
                          value={n_data?.expiry_date}
                          onChange={(e) =>
                            setNdata((d) => ({
                              ...d,
                              expiry_date: e.target.value,
                            }))
                          }
                        />
                      </Col>
                    </Row>
                  </Col>
                  <Col span={24}>
                    <Row justify="end" gutter={[16, 16]}>
                      <Col
                        style={{ textAlign: "right", alignContent: "flex-end" }}
                      >
                        {user && [2].includes(Number(user.role.staffRoleId)) ? (
                          <>
                            {data?.status ? (
                              <>
                                {edit ? (
                                  <CheckOutlined
                                    onClick={() => setEdit(false)}
                                    style={{ fontSize: 30, color: "blue" }}
                                    title="Done"
                                  />
                                ) : (
                                  <EditOutlined
                                    onClick={() => setEdit(true)}
                                    style={{ fontSize: 30, color: "blue" }}
                                  />
                                )}
                              </>
                            ) : null}
          
                            {data?.status ? (
                              <UsergroupAddOutlined
                                style={{
                                  fontSize: 30,
                                  color: "blue",
                                  marginleft: 20,
                                  margin: "0 20px",
                                }}
                                onClick={() => setShowEnrolleeAddition(true)}
                              />
                            ) : null}
                          </>
                        ) : null}
                      </Col>
                    </Row>
                  </Col>
                </Row>
              </Col>
            </Row>
          )}
        </Modal>
      )}
      {/* Social Code Update Modal */}
      {type === "clients" && (
        <Modal
          title="Plan Category Edit"
          open={updateCategory}
          onOk={updateUseCategory}
          onCancel={() => setUpdateCategory(false)}
          destroyOnClose
          footer={ user && [1, 2].includes(Number(user.role["staffRoleId"])) ? [
            <Button
              type="primary"
              onClick={updateUseCategory}
              disabled={!categoryVerified}
            >
              Update
            </Button>,
            <Button
              type="primary"
              danger
              onClick={() => setUpdateCategory(false)}
            >
              Close
            </Button>,
          ] : null}
        >
          <Row gutter={[12, 12]}>
            <Col span={6}>Policy Category</Col>
            <Col span={18}>
              <Select
                value={categoryUpdateData?.category || n_data?.category}
                onChange={(e) =>
                  setCategoryUpdateData((d) => ({ ...d, category: e }))
                }
                options={[
                  { label: "Self Pay", value: "self pay" },
                  { label: "Corporate", value: "corporate" },
                ]}
                style={{ width: "100%" }}
              />
            </Col>
            {(categoryUpdateData?.category === "corporate" ||
              n_data?.category === "corporate") && (
              <>
                <Col span={6}>Assocaition Code</Col>
                <Col span={14}>
                  <Input
                    value={categoryUpdateData?.social_no || n_data?.social_no}
                    onChange={(e) =>
                      setCategoryUpdateData((d) => ({
                        ...d,
                        social_no: e.target.value,
                      }))
                    }
                    style={{ width: "100%" }}
                  />
                </Col>
                <Col span={4}>
                  <Button
                    type="dashed"
                    danger
                    onClick={verifyCode}
                    style={{ width: "100%" }}
                    disabled={updateCategoryValidating}
                  >
                    verify
                  </Button>
                </Col>
                <Col span={6}></Col>
                <Col span={18}>{UpdateSocialDetail?.org_name}</Col>
              </>
            )}
          </Row>
        </Modal>
      )}

      <DependantEdit
        style={{ top: 10 }}
        isModalVisible={showEnrolleeAddition}
        handleCancel={() => setShowEnrolleeAddition(false)}
        data={data}
        command={() => setShowEnrolleeAddition(false)}
      />

      <FacilityPassword open={showSetFacilityPassword} onCancel={() => setShowSetFacilityPassword(false)} data={data} onDone = {command} />
    </>
  );
};

const FacilityPassword = props => {
  const [password, setPassword] = useState()

  const handleSavePassword = () => {
    const payload = {
      id: props.data.facility_id,
      password
    }

    myRequestObj('setFacilityPassword').post(payload)
      .then(resp => {
        const data = resp?.data;
        if(!data?.error){
          notification.success({
            message: data?.message
          })
          props.onDone()
          props.onCancel()
        } else {
          notification.success({
            message: data?.msg || data?.message
          })
        } 
      })
  }

  return <Modal
    title={'Set Password'}
    cancelText={'Close'}
    okText={'Save'}
    onCancel={props.onCancel}
    onOk={handleSavePassword}
    open={props.open}
    width={500}
    destroyOnClose
  >
    <Row>
      <Col span={24}>Password</Col>
      <Col span={24}>
        <Input.Password value={password} onChange={e => setPassword(e.target.value)} width={'100%'} />
      </Col>
    </Row>
  </Modal>
}

const DependantEdit = (props) => {
  const [values, setValues] = useState({});
  const [, setError] = React.useState(false);
  const [loading, setLoading] = useState(false);
  const [stateHealthCare, setStateHealtCare] = useState([]);
  const { user } = useAuth();

  const [isPrincipal, setIsPrincipal] = useState(false);

  const { Option } = Select;

  const options = [
    { label: "Male", value: "Male" },
    { label: "Female", value: "Female" },
  ];
  const optionsMarital = [
    { label: "Spouse", value: "spouse" },
    { label: "Child", value: "child" },
    { label: "Other", value: "other" },
  ];

  const selectDate = (_, dateString) => {
    setValues((d) => ({ ...d, birth_day: dateString }));
  };

  // Getting principal
  useEffect(() => {
    if (props.isModalVisible) {
      (async () => {
        setLoading(true);

        let payload = {
          policy_no: props?.data?.policy_no,
        };

        let reqData = await lib.principal(payload);
        if (reqData?.error === 1) {
          notification.error({
            message: reqData?.message,
            duration: 4,
            placement: "topLeft",
          });
        }

        if (reqData?.status === "error") {
          notification.error({
            message: reqData?.msg || reqData?.message,
            duration: 4,
            placement: "topLeft",
          });
        }

        if (reqData?.error === 0) {
          let principal = reqData.data;
          setIsPrincipal(principal);
        }

        setLoading(false);
      })();
    }
  }, [props.isModalVisible]); // eslint-disable-line react-hooks/exhaustive-deps

  const fetchProviders = async (e) => {
    let obj = {
      state: e,
      plan_type: props?.data?.insurance_package,
    };
    let reqData = await lib.getProviders(obj);

    if (reqData?.data?.error === 1) {
      notification.error({
        message: reqData.data.message,
        duration: 3,
        placement: "topLeft",
      });
    }

    if (reqData?.error === 0) {
      setStateHealtCare(NullSafe(reqData.data));
      setLoading(false);
    }
  };

  const handleDependentSubmit = async () => {
    let builder = formValidator.validateDependent(values, {}, setError);

    if (!builder) {
      return;
    }

    setLoading(true);
    builder.policy_no = props?.data?.policy_no;
    builder.role = (isPrincipal ? "Dependent" : "Principal").toLowerCase();

    let reqData = await lib.saveNewProfile(builder);

    if (reqData.data?.error === 1) {
      notification.error({
        message: reqData.data.message,
        placement: "topLeft",
      });
    }

    if (reqData.data?.error === 0) {
      notification.success({
        message: reqData.data.message,
        placement: "topLeft",
      });
      props.handleCancel();
      props.command();
    }

    setLoading(false);
    props.command();
  };

  return (
    <>
      <Modal
        width={1000}
        open={props.isModalVisible}
        footer={
          user && [1, 2].includes(Number(user.role["staffRoleId"])) ? (
          <>
            <Button disabled={loading} onClick={handleDependentSubmit}>
              {loading ? <LoadingOutlined /> : <>Add Enrollee</>}
            </Button>
            <Button
              type="dashed"
              disabled={loading}
              danger
              onClick={props.command}
            >
              Close
            </Button>
          </>) : null
        }
        closable={true}
        destroyOnClose
      >
        <div style={{ width: "100%", margin: "auto" }}>
          {loading ? (
            <LoadingOutlined size={50} />
          ) : (
            <div className="profile-top">
              <Row style={{ width: "100%" }} gutter={[16, 16]}>
                <Col span={24}>
                  <Row style={{ width: "100%" }} gutter={16}>
                    <Col span={12} xs={24} sm={24}>
                      <Row gutter={16} align="middle">
                        <Col span={8}>Insurance package:</Col>
                        <Col span={16}>
                          <Input
                            disabled
                            value={props?.data?.insurance_package}
                            placeholder="Basic, Bronze, Silver ..."
                            style={{ width: "100%" }}
                          />
                        </Col>
                      </Row>
                    </Col>
                    <Col span={12} xs={24} sm={24}>
                      <Row gutter={16} align="middle">
                        <Col span={8}>Role:</Col>
                        <Col span={16}>
                          <Input
                            value={isPrincipal ? "Dependent" : "Principal"}
                            disabled
                            style={{ width: "100%" }}
                          />
                        </Col>
                      </Row>
                    </Col>
                  </Row>
                </Col>

                <Col span={24}>
                  <Row style={{ width: "100%" }} gutter={[16, 16]}>
                    <Col span={24}>
                      <Row gutter={[16, 16]} align="middle">
                        <Col span={4}>Title:</Col>
                        <Col span={20}>
                          <Select
                            onChange={(e) =>
                              setValues((d) => ({ ...d, title: e }))
                            }
                            value={values?.title}
                            options={title}
                            style={{ width: "100%" }}
                          />
                        </Col>
                      </Row>
                    </Col>
                    <Col span={12}>
                      <Row gutter={16} align="middle">
                        <Col span={8}>First Name:</Col>
                        <Col span={16}>
                          <Input
                            onChange={(e) =>
                              setValues((d) => ({
                                ...d,
                                first_name: e.target.value,
                              }))
                            }
                            value={values?.first_name}
                            placeholder="John"
                            style={{ width: "100%" }}
                          />
                        </Col>
                      </Row>
                    </Col>
                    <Col span={12}>
                      <Row gutter={16} align="middle">
                        <Col span={8}>Middle Name:</Col>
                        <Col span={16}>
                          <Input
                            onChange={(e) =>
                              setValues((d) => ({
                                ...d,
                                middle_name: e.target.value,
                              }))
                            }
                            value={values?.middle_name}
                            placeholder="Doe"
                            style={{ width: "100%" }}
                          />
                        </Col>
                      </Row>
                    </Col>
                  </Row>
                </Col>

                <Col span={24}>
                  <Row style={{ width: "100%" }} gutter={16}>
                    <Col span={12}>
                      <Row gutter={16} align="middle">
                        <Col span={8}>Surname:</Col>
                        <Col span={16}>
                          <Input
                            onChange={(e) =>
                              setValues((d) => ({
                                ...d,
                                surname: e.target.value,
                              }))
                            }
                            value={values?.surname}
                            placeholder="Clark"
                            style={{ width: "100%" }}
                          />
                        </Col>
                      </Row>
                    </Col>
                    <Col span={12}>
                      <Row gutter={16} align="middle">
                        <Col span={8}>Date of Birth:</Col>
                        <Col span={16}>
                          <DatePicker
                            style={{ width: "100%" }}
                            onChange={selectDate}
                          />
                        </Col>
                      </Row>
                    </Col>
                  </Row>
                </Col>

                <Col span={24}>
                  <Row style={{ width: "100%" }} gutter={16}>
                    <Col span={12}>
                      <Row gutter={16} align="middle">
                        <Col span={8}>Gender:</Col>
                        <Col span={16}>
                          <Radio.Group
                            style={{ marginLeft: "20px" }}
                            options={options}
                            onChange={(e) => {
                              setValues((d) => ({
                                ...d,
                                gender: e.target.value,
                              }));
                            }}
                            value={values?.gender}
                          />
                        </Col>
                      </Row>
                    </Col>
                    <Col span={12}>
                      <Row gutter={16} align="middle">
                        <Col span={8}>Relationship:</Col>
                        <Col span={16}>
                          <Radio.Group
                            style={{ marginLeft: "20px" }}
                            options={optionsMarital}
                            onChange={(e) => {
                              setValues((d) => ({
                                ...d,
                                relationship: e.target.value,
                              }));
                            }}
                            value={values?.relationship}
                          />
                        </Col>
                      </Row>
                    </Col>
                  </Row>
                </Col>

                <Col span={24}>
                  <Row style={{ width: "100%" }} gutter={16}>
                    <Col span={12}>
                      <Row gutter={16} align="middle">
                        <Col span={8}>Email:</Col>
                        <Col span={16}>
                          <Input
                            onChange={(e) =>
                              setValues((d) => ({
                                ...d,
                                email: e.target.value,
                              }))
                            }
                            value={values?.email}
                            placeholder="example@gmail.com"
                            style={{ width: "100%" }}
                          />
                        </Col>
                      </Row>
                    </Col>
                    <Col span={12}>
                      <Row gutter={16} align="middle">
                        <Col span={8}>Phone number:</Col>
                        <Col span={16}>
                          <Input
                            onChange={(e) =>
                              setValues((d) => ({
                                ...d,
                                phone: e.target.value,
                              }))
                            }
                            value={values?.phone}
                            placeholder="09000000000"
                            style={{ width: "100%" }}
                          />
                        </Col>
                      </Row>
                    </Col>
                  </Row>
                </Col>

                <Col span={24}>
                  <Row style={{ width: "100%" }} gutter={16}>
                    <Col span={12}>
                      <Row gutter={16} align="middle">
                        <Col span={8}>State of Residence;</Col>
                        <Col span={16}>
                          <Select
                            showSearch
                            defaultValue={"Select state"}
                            style={{ width: "100%", marginRight: "10px" }}
                            onChange={(e) => {
                              setValues((d) => ({ ...d, state: e }));
                              fetchProviders(e);
                            }}
                            value={values?.state}
                            optionFilterProp="children"
                            filterOption={(input, option) =>
                              (option?.label ?? "").includes(input) ||
                              (option?.value ?? "").includes(input)
                            }
                            filterSort={(optionA, optionB) =>
                              (optionA?.label ?? "")
                                .toLowerCase()
                                .localeCompare(
                                  (optionB?.label ?? "").toLowerCase()
                                ) ||
                              (optionA?.value ?? "")
                                .toLowerCase()
                                .localeCompare(
                                  (optionB?.value ?? "").toLowerCase()
                                )
                            }
                          >
                            {states?.map((item) => (
                              <>
                                <Option value={item.value}>{item.label}</Option>
                              </>
                            ))}
                          </Select>
                        </Col>
                      </Row>
                    </Col>
                    <Col span={12}>
                      <Row gutter={16} align="middle">
                        <Col span={8}>City / LGA:</Col>
                        <Col span={16}>
                          <Input
                            onChange={(e) =>
                              setValues((d) => ({ ...d, lga: e.target.value }))
                            }
                            value={values?.lga}
                            placeholder="lga"
                            style={{ width: "100%", marginRight: "10px" }}
                          />
                        </Col>
                      </Row>
                    </Col>
                  </Row>
                </Col>

                <Col span={24}>
                  <Row style={{ width: "100%" }} gutter={16}>
                    <Col span={12}>
                      <Row gutter={16} align="middle">
                        <Col span={8}>Religion:</Col>
                        <Col span={16}>
                          <Select
                            defaultValue={values?.religion}
                            style={{ width: "100%" }}
                            onChange={(e) =>
                              setValues((d) => ({ ...d, religion: e }))
                            }
                            placeholder="select religion"
                          >
                            <Option value="christianity">Christianity</Option>
                            <Option value="islam">Islam</Option>
                            <Option value="others">Others</Option>
                          </Select>
                        </Col>
                      </Row>
                    </Col>
                    <Col span={12}>
                      <Row gutter={16} align="middle">
                        <Col span={8}>Occupatoin:</Col>
                        <Col span={16}>
                          <Input
                            onChange={(e) =>
                              setValues((d) => ({
                                ...d,
                                job_title: e.target.value,
                              }))
                            }
                            value={values?.job_title}
                            placeholder="Engineer"
                            style={{ width: "100%" }}
                          />
                        </Col>
                      </Row>
                    </Col>
                  </Row>
                </Col>

                <Col span={24}>
                  <Row style={{ width: "100%" }} gutter={16} align="middle">
                    <Col span={4}>Contact Address:</Col>
                    <Col span={20}>
                      <TextArea
                        onChange={(e) =>
                          setValues((d) => ({
                            ...d,
                            contact_address: e.target.value,
                          }))
                        }
                        value={values?.contact_address}
                        showCount
                        style={{ width: "100%" }}
                        rows={3}
                      />
                    </Col>
                  </Row>
                </Col>

                <Col span={24}>
                  <Row style={{ width: "100%" }} gutter={16}>
                    <Col span={4}>Primary Health Care Facility:</Col>
                    <Col span={20}>
                      <Select
                        style={{ width: "100%" }}
                        onChange={(e) =>
                          setValues((d) => ({
                            ...d,
                            primary_health_facility: e,
                          }))
                        }
                        value={values?.primary_health_facility}
                      >
                        {stateHealthCare?.map((item) => (
                          <>
                            <Option
                              value={item.facility_name}
                              title={
                                item.facility_name +
                                " - " +
                                item.facility_plan_type.toUpperCase()
                              }
                              style={{ borderBottom: "1px solid #91caff" }}
                            >
                              <span>
                                {item.facility_name}{" "}
                                <Divider
                                  type="vertical"
                                  style={{ margin: 5, borderColor: "#001d66" }}
                                />{" "}
                                {item.facility_plan_type.toUpperCase()}
                              </span>
                              <Divider style={{ margin: 0 }} />
                              <span>Address: {item.facility_address}</span>
                              <Divider style={{ margin: 0 }} />
                              <span>
                                State: {item.facility_state}{" "}
                                <Divider
                                  type="vertical"
                                  style={{ margin: 5, borderColor: "#001d66" }}
                                />{" "}
                                City: {item.facility_city.toUpperCase()}
                              </span>
                            </Option>
                          </>
                        ))}
                      </Select>
                    </Col>
                  </Row>
                </Col>

                <Col span={24}>
                  <Row style={{ width: "100%" }} gutter={16}>
                    <Col span={4}>Secondary Health Care Facility:</Col>
                    <Col span={20}>
                      <Select
                        style={{ width: "100%", marginRight: "10px" }}
                        onChange={(e) =>
                          setValues((d) => ({
                            ...d,
                            secondary_health_facility: e,
                          }))
                        }
                        value={values?.secondary_health_facility}
                      >
                        {stateHealthCare?.map((item) => (
                          <>
                            <Option
                              value={item.facility_name}
                              title={
                                item.facility_name +
                                " - " +
                                item.facility_plan_type.toUpperCase()
                              }
                              style={{ borderBottom: "1px solid #91caff" }}
                            >
                              <span>
                                {item.facility_name}{" "}
                                <Divider
                                  type="vertical"
                                  style={{ margin: 5, borderColor: "#001d66" }}
                                />{" "}
                                {item.facility_plan_type.toUpperCase()}
                              </span>
                              <Divider style={{ margin: 0 }} />
                              <span>Address: {item.facility_address}</span>
                              <Divider style={{ margin: 0 }} />
                              <span>
                                State: {item.facility_state}{" "}
                                <Divider
                                  type="vertical"
                                  style={{ margin: 5, borderColor: "#001d66" }}
                                />{" "}
                                City: {item.facility_city.toUpperCase()}
                              </span>
                            </Option>
                          </>
                        ))}
                      </Select>
                    </Col>
                  </Row>
                </Col>

                <Col span={24}>
                  <Row
                    style={{ width: "100%", textAlign: "center" }}
                    gutter={16}
                    justify="center"
                  >
                    <Col span={24}>
                      <h4>Medical Condition(s)</h4>
                    </Col>
                  </Row>
                </Col>

                <Col span={24}>
                  <Row style={{ width: "100%" }} gutter={16} align="middle">
                    <Col span={4}>Existing Medical Condition:</Col>
                    <Col span={20}>
                      <TextArea
                        onChange={(e) =>
                          setValues((d) => ({
                            ...d,
                            existing_medical_condition: e.target.value,
                          }))
                        }
                        value={values?.existing_medical_condition}
                        showCount
                        style={{ width: "100%" }}
                        rows={3}
                      />
                    </Col>
                  </Row>
                </Col>

                <Col span={24}>
                  <Row style={{ width: "100%" }} gutter={16} align="middle">
                    <Col span={4}>Previous Medical Condition:</Col>
                    <Col span={20}>
                      <TextArea
                        onChange={(e) =>
                          setValues((d) => ({
                            ...d,
                            previous_medical_condition: e.target.value,
                          }))
                        }
                        value={values?.previous_medical_condition}
                        showCount
                        style={{ width: "100%" }}
                        rows={3}
                      />
                    </Col>
                  </Row>
                </Col>

                {/* <Col span={24}>
                        <Row style={{width: '100%'}} gutter={16} align='middle' justify={'center'}>
                            <Col span={12} style={{display: 'flex', justifyContent: 'center'}}>
                                <ButtonComponent style={{padding: '15px auto', width: 'fit-content', width: '80%'}}  content={loading? <Loader type="Oval" color="#00BFFF" height={20} visible={loading} width={20} />: "Submit"} onClick={handleDependentSubmit} disabled={loading}/>
                            </Col>
                            <Col span={12} style={{display: 'flex', justifyContent: 'center'}}>
                                <ButtonComponent style={{padding: '15px auto', width: 'fit-content', width: '50%', color:'red'}} content="Cancel" onClick={props.handleCancel} disabled={loading} />
                            </Col>
                        </Row>
                    </Col> */}
              </Row>
            </div>
          )}
        </div>
      </Modal>
    </>
  );
};

export default ClientVerufyHook;
