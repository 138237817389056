import React, { useEffect } from 'react';
import { createRoot } from 'react-dom/client';
import { Provider } from 'react-redux';
import { BrowserRouter as Router, Routes, Route, useNavigate } from "react-router-dom";
import AuthProvider, { useAuth } from './assets/utils/useAuth'
import { store } from './app/store';
import reportWebVitals from './reportWebVitals';
import './index.css';
import './features/index/App.css';
import Layout from './features/layout';
import ClientVerufyHook from './features/clients';
import CheckinListHook from './features/checkins';
import LoginPageHook from './features/login';
import AuthorizationRequestHook from './features/preauth';
import ClaimsHook from './features/claims';
import SuperAdmin from './features/dash';
import Dashboard from './features/dashboard';
import PAFiling from './features/preauth/paFiling';
import ClaimFiling from './features/claims/paFiling'
import ClientDetails from './features/search';
import Helpers from './assets/utils/Helpers';

import { useDispatch} from 'react-redux'
import Organizations from './features/orgs';
import Plans from './features/plans';
import OrganizationDetail from './features/orgs/organizationalDetail';
import Costing from './features/pricing';
import PlanDetail from './features/plans/planDetail';
import { Logs } from './features/logging';

const container = document.getElementById('root');
const root = createRoot(container);

const Logout = () => {
  const {set} = useAuth();
  const navigate = useNavigate();
  const dispatcher = useDispatch();

  useEffect(() => {
    Helpers.logout(set, dispatcher);
    navigate('/login');
  }, []) //eslint-disable-line react-hooks/exhaustive-deps

  return(
    <></>
  )
}

root.render(
  <React.StrictMode>
    <AuthProvider>
      <Provider store={store}>
        <Router>
          <Routes>
            <Route path="/" element={<Layout />}>
              <Route index element={<LoginPageHook />} />
              <Route path='/login' element={<LoginPageHook />} />
              <Route path='/verify' element={<ClientVerufyHook />} />
              <Route path='/search' element={<ClientDetails />} />
              <Route path='/checkins' element={<CheckinListHook />} />
              <Route path='/preauth' element={<AuthorizationRequestHook />} />
              <Route path='/preauth-action' element={<PAFiling />} />
              <Route path='/dash' element={<SuperAdmin />} />
              <Route path='/dashboard' element={<Dashboard />} />
              <Route path='/claims' element={<ClaimsHook />} />
              <Route path='/claims-action' element={<ClaimFiling />} />

              <Route path='/corporations' >
                <Route index  element={<Organizations />} />
                <Route path='/corporations/users/:org'  element={<OrganizationDetail />} />
              </Route>
              <Route path='/costing' element={<Costing />} />
              
              <Route path='/log' element={<Logs />} />

              <Route path='/plans' >
                <Route index element={<Plans />} />
                <Route path='/plans/:plan'  element={<PlanDetail />} />
              </Route>
              <Route path='/logout' element={<Logout />} />
              <Route path='*' element={<LoginPageHook />} />
            </Route>
          </Routes>
        </Router>
      </Provider>
    </AuthProvider>
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
