import axios from "axios";

export const isDev = () =>   !process.env.NODE_ENV || process.env.NODE_ENV === 'development';

// export const uri = isDev()? 'https://api.amanmedicare.com/v1.1/slim/test/' : 'https://api.amanmedicare.com/v1.1/slim/';
export const uri = 'https://api.amanmedicare.com/v1.1/slim/test/';
// export const uri = 'https://api.amanmedicare.com/v1.1/slim/';
export const uri_img = 'https://api.amanmedicare.com/v1.1/';

const inDev = isDev;

export const header =  {
  'Access-Control-Allow-Origin': '*',
  'Content-Type': 'application/json',
  'Cache-Control': 'no-cache, no-store, must-revalidate',
  'Pragma': 'no-cache',
  'Expires': 0,
  'Version': '1.5.1',
  'Application': 2,
  'Dev':  inDev? 1 : 0
}

  
const Axios = axios.create({baseURL: uri, headers: header });

export default Axios;