import React, { useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { Dropdown, Space } from "antd";
import { UserOutlined } from "@ant-design/icons";

import Helpers from "../assets/utils/Helpers";
import wav2 from "./../assets/enrollee.wav";
import lib from "../assets/utils/lib";
import { add_client } from "../stores/verify";
import config from "../assets/utils/config";
import logo from "../assets/images/logo.png";
import { useAuth } from "../assets/utils/useAuth";

let audio = document.querySelector("#audio");

const HeaderHook = () => {
  const { set, user } = useAuth();
  const navigate = useNavigate();
  const dispatcher = useDispatch();
  const [currentData, setCurrentData] = useState([]);
  const clientDate = useSelector((state) => state.client);
  const activeSeg = useRef(0);
  const interval = useRef();

  useEffect(() => {
    if (user) {
      setTimeout(() => showDetail(), 2000);
      if (interval.current) clearInterval(interval.current);
      interval.current = setInterval(async () => {
        update(clientDate, audio);
      }, 10000);
    } else {
      if (interval.current) clearInterval(interval.current);
    }
  }, [user, interval, clearInterval]); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    const dt2 =
      currentData?.enrollees?.new?.length +
      currentData?.enrollees?.updated?.length +
      currentData?.clients?.new?.length +
      currentData?.clients?.updated?.length;

    activeSeg.current = dt2;

    if (dt2 && !document.hasFocus()) {
      if (audio) {
        audio.src = wav2;
        document.body.click();
        audio.muted = false;
      }
    }

    dispatcher(add_client(currentData));
  }, [currentData, dispatcher]);

  const showDetail = async () => {
    if (
      localStorage.getItem(config.key.user) &&
      [1, 2].includes(Number(user?.role["staffRoleId"]))
    ) {
      const reqData = await lib.unverifiedClients({});
      if (!reqData?.data?.error) {
        setCurrentData(reqData?.data?.data);
      }
    }
    return;
  };

  const update = async () => {
    await showDetail(1);
  };

  function handleButtonClick(e) {

  }

  function handleMenuClick(e) {
    switch (e.key) {
      case "2":
        break;

      case "1":
        setTimeout(() => {
          Helpers.logout(set, dispatcher);
          navigate("/login");
        }, 2000);
        break;

      default:
        break;
    }
  }

  const items = [
    {
      key: '1',
      label: 'Logout',
      icon: <UserOutlined />,
    },
  ];

  const menuProps = {
    items,
    onClick: handleMenuClick,
  };

  return (
    <>
      <header>
        <nav className="navbar navbar-expand-lg navbar-light bg-light">
          <div className="container justify-content-betwween">
            <a className="navbar-brand me-2" href="/dashboard">
              <img src={logo} alt="Aman Medicare" />
            </a>

            <button
              className="navbar-toggler"
              type="button"
              data-mdb-toggle="collapse"
              data-mdb-target="#navbarButtonsExample"
              aria-controls="navbarButtonsExample"
              aria-expanded="false"
              aria-label="Toggle navigation"
            >
              <i className="fas fa-bars"></i>
            </button>
            {user ? (
              <>
                <div
                  className="collapse navbar-collapse gap-5"
                  id="navbarButtonsExample"
                  style={{ fontSize: "1.4em" }}
                >
                  <ul className="navbar-nav ms-auto mb-2 mb-lg-0 gap-3">
                    {user &&
                    [1, 2].includes(
                      Number(user.role["staffRoleId"])
                    ) ? (
                      <li className="nav-item">
                        <a className="nav-link" href="/verify">
                          Client
                          <span
                            className="badge rounded-pill badge-notification bg-danger"
                            id="userCount"
                            style={{ marginLeft: 3 }}
                          >
                            {activeSeg.current ? activeSeg.current : null}
                          </span>
                        </a>
                      </li>
                    ) : null}

                    {
                      <li className="nav-item">
                        <a className="nav-link" href="/search">
                          Search
                        </a>
                      </li>
                    }

                    {![1, 5].includes(Number(user.role.staffRoleId)) ? (
                      <li className="nav-item">
                        <a className="nav-link" href="/checkins">
                          Check-Ins
                        </a>
                      </li>
                    ) : null}
                    {[1, 2, 4].includes(Number(user.role.staffRoleId)) ? (
                      <li className="nav-item">
                        <a className="nav-link" href="/preauth">
                          PAR
                        </a>
                      </li>
                    ) : null}

                    {[1, 3, 4, 5].includes(Number(user.role.staffRoleId)) ? (
                      <li className="nav-item">
                        <a className="nav-link" href="/claims">
                          Claims
                        </a>
                      </li>
                    ) : null}

                    {[1, 6, 7].includes(Number(user.role.staffRoleId)) ? (
                      <li className="nav-item">
                        <a className="nav-link" href="/dash">
                          Staffs
                        </a>
                      </li>
                    ) : null}

                    {[1, 5].includes(Number(user.role.staffRoleId)) ? (
                      <li className="nav-item">
                        <a className="nav-link" href="/corporations">
                          Orgs
                        </a>
                      </li>
                    ) : null}

                    {[1, 5].includes(Number(user.role.staffRoleId)) ? (
                      <li className="nav-item">
                        <a className="nav-link" href="/plans">
                          Plans
                        </a>
                      </li>
                    ) : null}

                    {[1, 3, 4].includes(Number(user.role.staffRoleId)) ? (
                      <li className="nav-item">
                        <a className="nav-link" href="/costing">
                          Costing
                        </a>
                      </li>
                    ) : null}

                    <li className="nav-item">
                      <a className="nav-link" href="/log">
                        Log
                      </a>
                    </li>
                  </ul>
                  <div className="d-flex">
                    {user ? (
                      <Space wrap>
                        <Dropdown.Button menu={menuProps} onClick={handleButtonClick} style={{color: '#3597d3'}} icon={<UserOutlined />}>{user.biodata?.firstName || user.staffId}</Dropdown.Button>
                      </Space>
                    ) : null}
                  </div>
                </div>
              </>
            ) : null}
          </div>
        </nav>
      </header>
    </>
  );
};

export default HeaderHook;
